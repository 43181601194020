import { useMemo } from 'react'
import { DOCUMENTTYPE_OPTIONS } from '../controls/SelectOpportunityDocuments'

function CreateProductMap(productTypes, documents, categoriesMap) {
  let pMap = {}

  if (productTypes && documents && categoriesMap) {
    for (let product of productTypes) {
      pMap[product.id] = { productInfo: product, documents: {}, productNumbers: product.productItemNumbers }
    }

    for (let doc of documents) {
      for (let docProdId of doc.crmProducts) {
        if (
          pMap[docProdId] &&
          !pMap[docProdId].documents[doc.id] &&
          categoriesMap[doc.documentType] &&
          DOCUMENTTYPE_OPTIONS.includes(categoriesMap[doc.documentType].name)
        ) {
          pMap[docProdId].documents[doc.id] = doc
        }
      }
    }
  }

  return pMap
}

export default function useProductDocumentMap(productTypes, documents, categoriesMap) {
  return useMemo(() => CreateProductMap(productTypes, documents, categoriesMap), [productTypes, documents, categoriesMap])
}
