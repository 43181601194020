import { CustomDataGrid, WtxColors } from '@wavetronix/common-components'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import DownloadingIcon from '@mui/icons-material/Downloading'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import { getVideoType } from '../../utils/stringUtils'

export default function DownloadOpportunityDocuments({ selectedDocs, STATE_OPTIONS, docMap }) {
  const stateIconTranlation = state => {
    if (state === 0) {
      return <PauseCircleIcon sx={{ color: WtxColors.ASPHALT }} />
    } else if (state === 1) {
      return <DownloadingIcon sx={{ color: WtxColors.IQ_BLUE }} />
    } else if (state === 2) {
      return <CheckCircleIcon sx={{ color: WtxColors.GROWTH_GREEN }} />
    } else {
      return <ErrorIcon sx={{ color: WtxColors.INNOVATION_RED }} />
    }
  }

  const columns = [
    {
      headerName: 'File',
      field: 'id',
      flex: 1,
      valueGetter: data => (!!docMap[data.row.id] ? docMap[data.row.id].fileName : data.row.id)
    },
    {
      headerName: 'Downoading File',
      field: 'fileDownloadState',
      flex: 1,
      renderCell: state => stateIconTranlation(state.value)
    },
    {
      headerName: 'Translating to PDF',
      field: 'pdfTranslationState',
      flex: 1,
      renderCell: state => stateIconTranlation(state.value)
    }
  ]

  return (
    <>
      <CustomDataGrid
        rows={selectedDocs ? Object.keys(selectedDocs).map(doc => ({ id: doc, ...selectedDocs[doc] })) : []}
        columns={columns}
        getRowClassName={params => {
          let result = getVideoType(params.id).isSuccess
          return `isHidden-${result}`
        }}
      />
    </>
  )
}
