import { DialogContent, Dialog, DialogActions } from '@mui/material'
import { CenteredDiv, SuccessButton, PrimaryButton } from '@wavetronix/common-components'

export default function VerifyModal({ warningModal, setWarningModal }) {
  return (
    <>
      <Dialog
        id='verifyModal'
        open={warningModal}
        keepMounted
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
        maxWidth='xs'
      >
        <DialogContent>
          Please be sure to verify the pdf was created to your specifications before sending to customers or uploading to CRM.
        </DialogContent>
        <DialogActions>
          <SuccessButton id='verifyButton' style={{ width: '150px' }} onClick={() => setWarningModal(false)}>
            Verify
          </SuccessButton>
        </DialogActions>
      </Dialog>
      <div>
        <CenteredDiv>
          <h2>Finished creating submittal!</h2>
        </CenteredDiv>
        <CenteredDiv>Either close window by clicking finish or by closing the tab manually.</CenteredDiv>
        <CenteredDiv>
          <PrimaryButton id='resetBundleButton' style={{ width: '300px', marginTop: '25px' }} onClick={() => window.close()}>
            Finish
          </PrimaryButton>
        </CenteredDiv>
      </div>
    </>
  )
}
