import { Dialog } from '@mui/material'
import { getVideoType } from '../../utils/stringUtils'
import { useMemo } from 'react'
import { env } from '../../index.js'

export default function PlayVideoModal({ videoInfo, open, onClose, token, unitId }) {
  const videoMeta = useMemo(() => {
    if (videoInfo) {
      return getVideoType(videoInfo.fileName)
    } else return { isSuccess: false }
  }, [videoInfo])

  if (videoMeta.isSuccess && token && token !== '') {
    return (
      <Dialog id='playVideoModal' open={open} onClose={onClose} maxWidth={'md'}>
        <video controls autoPlay controlsList='nodownload'>
          <source src={`${env.urls.documentsURL}/${unitId}/video/throughtoken/${videoInfo.id}?token=${token}`} />
          Your browser does not support the video tag.
        </video>
      </Dialog>
    )
  } else return null
}
