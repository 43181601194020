import { Box } from '@mui/system'
import { getResourceUrl, WtxColors, CenteredDiv } from '@wavetronix/common-components'
import React from 'react'
import { env } from '../../index.js'

const classes = {
  imageContainer: {
    minWidth: '100%',
    maxHeight: '90%',
    marginTop: 'auto'
  },
  productFamily: {
    color: 'black',
    '&:hover': {
      cursor: 'pointer',
      color: WtxColors.IQ_BLUE
    }
  }
}

export default function ProductFamilyCard({ product, currentState }) {
  return (
    <div style={classes.imageContainer}>
      <CenteredDiv>
        <img
          alt={`${product.name}`}
          src={`${getResourceUrl(env.runMode)}/images/products/${product.imageName}`}
          style={{ maxHeight: '45px' }}
        />
      </CenteredDiv>
      <Box sx={classes.productFamily}>
        <CenteredDiv>
          <h3
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              textTransform: 'none',
              color: currentState === product.name ? WtxColors.IQ_BLUE : 'black'
            }}>
            {product.name}
          </h3>
        </CenteredDiv>
      </Box>
    </div>
  )
}
