import {
  CircularProgress,
  TextField,
  List,
  ListItemButton,
  Grid,
  ListItemText,
  ListItemIcon,
  Card,
  CardContent,
  Box,
  IconButton,
  Tooltip,
  Switch,
  Stack,
  FormControlLabel,
  Snackbar
} from '@mui/material'
import { CenteredDiv, CustomCheckbox, CustomAccordion, ListSelector, WtxColors } from '@wavetronix/common-components'
import { useMemo, useState, useEffect } from 'react'
import { AddOutlined } from '@mui/icons-material'
import RemoveIcon from '@mui/icons-material/Remove'
import { STATE_OPTIONS } from '../modals/BundleWrapperModal'
import LanguageIcon from '@mui/icons-material/Language'
import { getVideoType } from '../../utils/stringUtils'

export const DOCUMENTTYPE_OPTIONS = [
  'Product Overview',
  'Technical Specifications',
  'Bid Specifications',
  'Accessory Product Overview',
  'Accessory Bid Specifications',
  'Accessory Technical Specifications',
  'Promotions',
  'Warranty'
]

export default function SelectOpportunityDocuments({
  productIdMap,
  setProductIdMap,
  selectedDocs,
  isLoading,
  setSelectedDocs,
  productDocMap,
  languageOptions,
  selectedLanguages,
  setSelectedLanguages,
  docMap,
  categoriesMap,
  productTableEditable = true
}) {
  const [docTypeOption, setDocTypeOption] = useState(DOCUMENTTYPE_OPTIONS.map(opt => ({ value: opt, id: opt })))
  const [productOption, setProductOption] = useState([])
  const [selectType, setSelectType] = useState(false)
  const [changeLanguage, setChangeLanguage] = useState(false)

  const PRODUCT_OPTIONS = useMemo(() => {
    let options = []
    if (productIdMap) {
      options = [
        ...Object.values(productIdMap)
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map(p => p.name)
      ]
    }

    return options
  }, [productIdMap])

  useEffect(() => {
    if (PRODUCT_OPTIONS) {
      setProductOption(PRODUCT_OPTIONS.map(opt => ({ value: opt, id: opt })))
    }
  }, [PRODUCT_OPTIONS])

  useEffect(() => {
    if (docMap) {
      //if the doc map changes selectedDocs may be old... remove any docs from selected that aren't in docmap
      setSelectedDocs(allDocs => {
        let newValidDocs = {}
        return Object.keys(allDocs).reduce((map, obj) => {
          if (!!docMap[obj]) {
            map[obj] = allDocs[obj]
          }
          return map
        }, newValidDocs)
      })
    }
  }, [docMap, setSelectedDocs])

  const addDocuments = () => {
    let selectedProducts = productOption.map(o => o.value)
    let selectedDocTypes = docTypeOption.map(o => o.value)
    let docsMap = Object.values(productIdMap)
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .filter(p => (selectedProducts.includes('All Products') ? true : selectedProducts.includes(p.name) ? true : false))
      .reduce((map, obj) => {
        let filteredDocs = Object.values(obj.documents).filter(d => {
          if (
            d.language.filter(p => selectedLanguages.map(fp => fp.id).includes(p)).length > 0 ||
            selectedLanguages.length === 0
          ) {
            if (
              selectedDocTypes.length === DOCUMENTTYPE_OPTIONS.length ||
              (categoriesMap &&
                categoriesMap[d.documentType] &&
                selectedDocTypes.some(opt => categoriesMap[d.documentType].name.includes(opt)))
            ) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })

        for (let doc of filteredDocs.sort((a, b) => {
          if (categoriesMap && categoriesMap[a.documentType] && categoriesMap[b.documentType]) {
            return DOCUMENTTYPE_OPTIONS.indexOf(categoriesMap[a.documentType].name) >
              DOCUMENTTYPE_OPTIONS.indexOf(categoriesMap[b.documentType].name)
              ? 1
              : -1
          } else {
            return 0
          }
        })) {
          map[doc.id] = {
            order: docMap[doc.id] ? docMap[doc.id].order : '1',
            fileDownloadState: STATE_OPTIONS.NOTSTARTED,
            pdfTranslationState: STATE_OPTIONS.NOTSTARTED
          }
        }

        return map
      }, {})

    setSelectedDocs({ ...docsMap })
  }

  const handleCheckAll = (e, setSelected, allOptions) => {
    if (e.target.checked === true) {
      setSelected(allOptions.map(opt => ({ id: opt, value: opt })))
    } else {
      setSelected([])
    }
  }

  const handleCheck = async (event, doc) => {
    if (event.target.checked === true) {
      //add to selected
      setSelectedDocs(docs => ({
        ...docs,
        [doc]: {
          ...docs[doc],
          order: docMap[doc] ? docMap[doc].order : '1',
          fileDownloadState: STATE_OPTIONS.NOTSTARTED,
          pdfTranslationState: STATE_OPTIONS.NOTSTARTED
        }
      }))
    } else {
      //remove from selected
      setSelectedDocs(docs => {
        var newDocs = { ...docs }
        delete newDocs[`${doc}`]
        return {
          ...newDocs
        }
      })
    }
  }

  const removeDocuments = () => {
    let selectedProducts = productOption.map(o => o.value)
    let selectedDocTypes = docTypeOption.map(o => o.value)
    let removeDocsMap = Object.values(productIdMap)
      .filter(p => (selectedProducts.includes('All Products') ? true : selectedProducts.includes(p.name) ? true : false))
      .reduce((map, obj) => {
        for (let doc of Object.entries(obj.documents).filter(([key, d]) => {
          if (
            d.language.filter(p => selectedLanguages.map(fp => fp.id).includes(p)).length > 0 ||
            selectedLanguages.length === 0
          ) {
            if (
              selectedDocTypes.length === DOCUMENTTYPE_OPTIONS.length ||
              (categoriesMap &&
                categoriesMap[d.documentType] &&
                selectedDocTypes.some(opt => categoriesMap[d.documentType].name.includes(opt)))
            ) {
              return true
            } else {
              return false
            }
          } else {
            return false
          }
        })) {
          map[doc[0]] = {
            order: '1',
            fileDownloadState: STATE_OPTIONS.NOTSTARTED,
            pdfTranslationState: STATE_OPTIONS.NOTSTARTED
          }
        }

        return map
      }, {})

    let newSelectedDoc = { ...selectedDocs }

    for (let doc of Object.keys(removeDocsMap)) {
      delete newSelectedDoc[`${doc}`]
    }
    setSelectedDocs({ ...newSelectedDoc })
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Object.keys(selectedDocs)
          .map(r => getVideoType(r).isSuccess)
          .includes(true)}
        message='Video files (indicated with grey, italized text) will not be downloaded.'
      />
      {isLoading || !productIdMap ? (
        <CenteredDiv>
          <CircularProgress />
        </CenteredDiv>
      ) : (
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '5px' }}>
              <Tooltip title='Document Languages'>
                <IconButton id='changeLangButton' onClick={() => setChangeLanguage(langValue => langValue === false)}>
                  <LanguageIcon sx={{ color: changeLanguage === true ? WtxColors.IQ_BLUE : 'default' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title='View All Documents'>
                <Switch id='changeDocViewSwitch' checked={selectType} onChange={e => setSelectType(e.target.checked)} />
              </Tooltip>
              <Box sx={{ flex: '1 1 auto' }} />
              {!selectType ? (
                <>
                  <Tooltip title='Update Documents'>
                    <IconButton id='addSelectedDocs' onClick={addDocuments}>
                      <AddOutlined />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title='Remove Documents'>
                    <IconButton id='removeSelectedDocs' onClick={removeDocuments}>
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : null}
            </Box>
            {changeLanguage === true ? (
              <CustomAccordion title={'Languages'} defaultExpanded>
                <ListSelector
                  selectedOptions={selectedLanguages}
                  options={
                    languageOptions
                      ? languageOptions.map(pType => ({
                          id: pType.id,
                          value:
                            pType.localization && pType.localization[window.navigator.language]
                              ? pType.localization[window.navigator.language]
                              : pType.name
                        }))
                      : []
                  }
                  onChange={languages => setSelectedLanguages(languages)}
                  style={{ width: '100%' }}
                />
              </CustomAccordion>
            ) : !selectType ? (
              <>
                <Stack sx={{ border: '1px solid rgba(0, 0, 0, 0.3)', borderRadius: '10px' }}>
                  <FormControlLabel
                    sx={{ marginLeft: '1px' }}
                    label='Document Type'
                    control={
                      <CustomCheckbox
                        id='allDocTypeCheckbox'
                        checked={docTypeOption.length === DOCUMENTTYPE_OPTIONS.length}
                        indeterminate={docTypeOption.length !== DOCUMENTTYPE_OPTIONS.length && docTypeOption.length !== 0}
                        onChange={e => handleCheckAll(e, setDocTypeOption, DOCUMENTTYPE_OPTIONS)}
                      />
                    }
                  />
                  <ListSelector
                    selectedOptions={docTypeOption}
                    options={DOCUMENTTYPE_OPTIONS.map(opt => ({ id: opt, value: opt }))}
                    onChange={options => {
                      setDocTypeOption([...options])
                    }}
                  />
                </Stack>
                <Stack sx={{ border: '1px solid rgba(0, 0, 0, 0.3)', borderRadius: '10px', marginTop: '10px' }}>
                  <FormControlLabel
                    sx={{ marginLeft: '1px' }}
                    label='Products'
                    control={
                      <CustomCheckbox
                        id='allProductsCheckbox'
                        checked={productOption.length === PRODUCT_OPTIONS.length}
                        indeterminate={productOption.length !== PRODUCT_OPTIONS.length && productOption.length !== 0}
                        onChange={e => handleCheckAll(e, setProductOption, PRODUCT_OPTIONS)}
                      />
                    }
                  />
                  <ListSelector
                    style={{ maxHeight: '400px' }}
                    selectedOptions={productOption}
                    options={PRODUCT_OPTIONS.map(opt => ({ id: opt, value: opt }))}
                    onChange={options => {
                      setProductOption([...options])
                    }}
                  />
                </Stack>
              </>
            ) : (
              <>
                {productIdMap ? (
                  Object.entries(productIdMap)
                    .sort(([aid, aproduct], [bid, bproduct]) => (aproduct.order > bproduct.order ? 1 : -1))
                    .map(([id, product], index) => {
                      return (
                        <Box key={index} sx={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '10px' }}>
                          <CustomAccordion
                            defaultExpanded={true}
                            disableGutters
                            title={`${product.productnumber !== '' ? `${product.productnumber} : ` : ''}
                          ${product.name}`}
                          >
                            {
                              <List dense>
                                {product.documents && Object.keys(product.documents).length > 0 ? (
                                  Object.values(product.documents)
                                    .sort((a, b) => {
                                      if (categoriesMap && categoriesMap[a.documentType] && categoriesMap[b.documentType]) {
                                        return DOCUMENTTYPE_OPTIONS.indexOf(categoriesMap[a.documentType].name) >
                                          DOCUMENTTYPE_OPTIONS.indexOf(categoriesMap[b.documentType].name)
                                          ? 1
                                          : -1
                                      } else {
                                        return 0
                                      }
                                    })
                                    .filter(d => {
                                      return (
                                        d.language.filter(p => selectedLanguages.map(fp => fp.id).includes(p)).length > 0 ||
                                        selectedLanguages.length === 0
                                      )
                                    })
                                    .map((doc, index) => {
                                      return (
                                        <ListItemButton
                                          id={`${doc.id}ListItemButton`}
                                          key={index}
                                          role={undefined}
                                          onClick={event => handleCheck(event, doc.id)}
                                          dense
                                        >
                                          <ListItemIcon>
                                            <CustomCheckbox
                                              id={`${doc.id}Checkbox`}
                                              edge='start'
                                              checked={selectedDocs[doc.id] ? true : false}
                                              tabIndex={-1}
                                              disableRipple
                                            />
                                          </ListItemIcon>
                                          <ListItemText primary={doc.fileName} />
                                        </ListItemButton>
                                      )
                                    })
                                ) : (
                                  <>
                                    <CenteredDiv>
                                      {product.productnumber === ''
                                        ? `This product has no item numbers assigned to it.`
                                        : 'The item number(s) is not associated with a product in Nexus or has no documents.'}
                                    </CenteredDiv>
                                    <CenteredDiv>Go to Documents Manager to resolve.</CenteredDiv>
                                  </>
                                )}
                              </List>
                            }
                          </CustomAccordion>
                        </Box>
                      )
                    })
                ) : (
                  <CircularProgress />
                )}
              </>
            )}
          </Grid>
          <Grid item md={6}>
            <Card sx={{ marginTop: '15px' }}>
              <CardContent>
                {selectedDocs && Object.keys(selectedDocs).length > 0 ? (
                  Object.keys(selectedDocs)
                    .sort((a, b) => {
                      if (Number(selectedDocs[a].order) > Number(selectedDocs[b].order)) {
                        return 1
                      } else {
                        return -1
                      }
                    })
                    .filter(doc => docMap[doc])
                    .map((doc, index) => {
                      return (
                        <Box key={index} sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: '100%' }}>
                          <span className={`isHidden-${getVideoType(docMap[doc].fileName).isSuccess}`}>
                            {docMap[doc].fileName}
                          </span>
                          <Box sx={{ flex: '1 1 auto' }} />
                          <TextField
                            id={`${doc}OrderTextField`}
                            value={selectedDocs[doc].order}
                            onChange={e =>
                              setSelectedDocs(sdocs => ({ ...sdocs, [doc]: { ...sdocs[doc], order: e.target.value } }))
                            }
                            size='small'
                            inputProps={{ min: 1 }}
                            type='number'
                            sx={{ width: '100px' }}
                          />
                        </Box>
                      )
                    })
                ) : (
                  <Box>No Documents Selected</Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  )
}
