import { MenuAppBar, RoleCheck, useWtxLocalization, NotFoundPage } from '@wavetronix/common-components'
import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom'
import { env } from '../index.js'
import { useMsal } from '@azure/msal-react'
import { useState, useMemo, useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'
import DocumentsPage from './DocumentsPage'
import ProductViewerPage from './pages/ProductViewerPage'
import OpportunitiesManagementPage from './pages/OpportunitiesManagementPage'
import SubmittalsManagementPage from './pages/SubmittalsManagementPage'
import PackageManagementPage from './pages/PackageManagementPage'
import BundleWrapperModal from './modals/BundleWrapperModal'
import CategoriesApi from '../api/CategoriesApi'
import version from '../env/version.json'
import CrmProductsApi from '../api/CrmProductsApi'
import PageNavbar from './PageNavbar'

const drawerWidth = 300

const classes = {
  paper: {
    width: drawerWidth
  },
  dataGrid: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  },
  filterTitle: {
    fontFamily: 'Klavika',
    fontSize: 22
  },
  title: {
    fontFamily: 'Klavika',
    fontSize: 26
  },
  inlineTitle: {
    fontFamily: 'Klavika',
    fontSize: '26px',
    display: 'inline'
  },
  pageHeader: {
    marginLeft: '24px',
    marginTop: '30px',
    fontSize: '24px',
    flex: 1,
    display: 'inline',
    justifyContent: 'left'
  },
  tableRow: {
    cursor: 'pointer'
  }
}

function MainPage() {
  const { instance, accounts } = useMsal()
  const localizedStrings = useWtxLocalization()
  const [state, setState] = useState({
    current: window.location.pathname.substring(1)
  })
  const [productState, setProductState] = useState({
    current: ''
  })
  const [pageState, setPageState] = useState('')
  document.getElementById('title').innerHTML = localizedStrings.title

  useEffect(() => {
    let windowPath = window.location.pathname.split('/').filter(part => part && part !== '')
    if (windowPath.length > 0) {
      setPageState(windowPath[0])
    } else {
      setPageState('documents')
    }
  }, [])

  const { data: productTypes } = useQuery({
    queryKey: ['crmProducts'],
    queryFn: async () => {
      let categories = await CrmProductsApi.getCrmProducts(instance, accounts)
      return categories
    }
  })

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => await CategoriesApi.getCategories(instance, accounts)
  })

  const categoriesMap = useMemo(() => {
    if (categories) {
      return categories.reduce((map, obj) => {
        map[obj.id] = obj
        return map
      }, {})
    }
  }, [categories])

  return (
    <div>
      <MenuAppBar env={env} fromCompany={env.basicAuthentication.fromCompany} />
      <div className='content'>
        <BrowserRouter future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
          <Routes>
            <Route path='/' element={<Navigate to='/documents/nexus' replace />} />
            <Route path='/documents/manufacturing' element={<Navigate to='/search/manufacturing' replace />} />
            <Route path='/documents/*' element={<Navigate to='/documents/nexus' replace />} />
            <Route path='/search/*' element={<Navigate to='/search/nexus' replace />} />
            <Route path='/nexus' element={<Navigate to='/documents/nexus' replace />} />
            <Route path='/manufacturing' element={<Navigate to='/search/manufacturing' replace />} />

            <Route
              element={
                <div style={{ display: 'flex' }}>
                  <PageNavbar value={pageState} setPageState={setPageState} accounts={accounts} env={env} />
                  <div style={{ margin: '10px', width: '100%' }}>
                    <Outlet />
                  </div>
                </div>
              }
            >
              <Route
                path='/documents/:unitId'
                element={
                  <DocumentsPage
                    styles={classes}
                    tabState={state}
                    changeTabState={s => {
                      setState(s)
                    }}
                    showFullList={false}
                    productState={productState}
                    setProductState={setProductState}
                  />
                }
              />
              <Route
                path='/search/:unitId'
                element={
                  <DocumentsPage
                    styles={classes}
                    tabState={state}
                    changeTabState={s => {
                      setState(s)
                    }}
                    showFullList={true}
                    productState={productState}
                    setProductState={setProductState}
                  />
                }
              />
              <Route
                path='/products/:unitId'
                element={
                  <RoleCheck env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Dealer', 'Packages Admin']}>
                    <SubmittalsManagementPage
                      productTypes={productTypes}
                      categoriesMap={categoriesMap}
                      instance={instance}
                      accounts={accounts}
                    />
                  </RoleCheck>
                }
              />
              <Route
                path='/packages/:unitId'
                element={
                  <RoleCheck env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Dealer', 'Packages Admin']}>
                    <PackageManagementPage
                      productTypes={productTypes}
                      categoriesMap={categoriesMap}
                      instance={instance}
                      accounts={accounts}
                    />
                  </RoleCheck>
                }
              />
              <Route
                path='/opportunities/:unitId'
                element={
                  <RoleCheck env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
                    <OpportunitiesManagementPage
                      productTypes={productTypes}
                      categoriesMap={categoriesMap}
                      instance={instance}
                      accounts={accounts}
                    />
                  </RoleCheck>
                }
              />
            </Route>
            <Route path='/byproduct/:unitId' element={<ProductViewerPage styles={classes} />} />
            <Route path='/version' element={<div>{version.version}</div>} />
            <Route
              path='/opportunitypackage/:unitId'
              element={
                <RoleCheck env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
                  <BundleWrapperModal
                    type={'opportunity'}
                    productTypes={productTypes}
                    categoriesMap={categoriesMap}
                    instance={instance}
                    accounts={accounts}
                  />
                </RoleCheck>
              }
            />
            <Route
              path='/manualpackage/:unitId'
              element={
                <RoleCheck env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
                  <BundleWrapperModal
                    type={'package'}
                    productTypes={productTypes}
                    categoriesMap={categoriesMap}
                    instance={instance}
                    accounts={accounts}
                  />
                </RoleCheck>
              }
            />
            <Route path='/*' element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  )
}
export default MainPage
