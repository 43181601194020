import { useEffect, useMemo } from 'react'
import { Stepper, Step, StepLabel, Box, Card } from '@mui/material'
import {
  PrimaryButton,
  SuccessButton,
  HasAccess,
  SnackbarVariants,
  DeleteButton,
  selectAccount
} from '@wavetronix/common-components'
import CreateOpportunityTitlePdf, { DEFAULT_SUBMITTAL_INFO } from '../controls/CreateOpportunityTitlePdf'
import SelectOpportunityDocuments from '../controls/SelectOpportunityDocuments'
import DownloadOpportunityDocuments from '../controls/DownloadOpportunityDocuments'
import { useMsal } from '@azure/msal-react'
import GroupsApi from '../../api/GroupsApi'
import PackagesApi from '../../api/PackagesApi'
import { env } from '../../index.js'
import { useSnackbar } from 'notistack'
import { useQuery } from '@tanstack/react-query'
import VerifyModal from './VerifyModal'
import { STATE_OPTIONS, STEPS } from './BundleWrapperModal'
import { clearAllCache } from '../../utils/cacheUtils'

export default function PackageBundleModal({
  productDocMap,
  selectedDocs,
  setSelectedDocs,
  activeStep,
  setActiveStep,
  submittalInfo,
  setSubmittalInfo,
  productIdMap,
  setProductIdMap,
  titlePageIncludes,
  setTitlePageIncludes,
  buttonsDisabled,
  setButtonsDisabled,
  titlePage,
  setTitlePage,
  selectedLanguages,
  setSelectedLanguages,
  warningModal,
  setWarningModal,
  htmlRef,
  LANGUAGE_OPTIONS,
  DEFAULT_DOC_ORDER,
  deactivateNext,
  handleNext,
  handleBack,
  categoriesMap
}) {
  const { enqueueSnackbar } = useSnackbar()
  const { instance, accounts } = useMsal()
  const account = selectAccount(accounts, env)

  const selectedPackage = useMemo(() => {
    const storedData = localStorage.getItem('selectedPackage')
    return JSON.parse(storedData)
  }, [])
  const storedProductIdMap = useMemo(() => {
    const storedData = localStorage.getItem('productIdMap')
    return JSON.parse(storedData)
  }, [])

  useEffect(() => {
    if (storedProductIdMap) {
      setProductIdMap(storedProductIdMap)
    }
  }, [storedProductIdMap, setProductIdMap])

  const { data: groupsData } = useQuery({
    queryKey: ['groupsAllQuery'],
    queryFn: async () => await GroupsApi.getGroups(instance, accounts)
  })

  useEffect(() => {
    if (selectedPackage && DEFAULT_DOC_ORDER) {
      let map = {}
      setSubmittalInfo(info => ({ ...info, projectName: selectedPackage.name, template: selectedPackage.template }))
      for (let doc of selectedPackage.selectedDocuments) {
        //should check to see if doc from package is still valid
        if (DEFAULT_DOC_ORDER[doc.id]) {
          map[doc.id] = {
            order: doc.order.toString(),
            fileDownloadState: STATE_OPTIONS.NOTSTARTED,
            pdfTranslationState: STATE_OPTIONS.NOTSTARTED
          }
        } else {
          console.log(`No valid product and document relationship for package.\nid: ${doc.id}`)
        }
      }
      setSelectedDocs(map)
    }
  }, [selectedPackage, DEFAULT_DOC_ORDER, setSelectedDocs, setSubmittalInfo])

  const createPackage = async () => {
    setButtonsDisabled(true)

    let pckg = {
      name: submittalInfo.projectName,
      createdBy: account.localAccountId,
      template: submittalInfo.template,
      products: Object.keys(productIdMap).map(id => ({ id: id, order: productIdMap[id].order })),
      selectedDocuments: Object.keys(selectedDocs).reduce((list, obj) => {
        list.push({
          id: obj,
          order: parseInt(selectedDocs[obj].order)
        })
        return list
      }, [])
    }

    await PackagesApi.createPackage(instance, accounts, pckg)
      .then(response => enqueueSnackbar(`Successfully create package ${pckg.name}`, SnackbarVariants.SUCCESS))
      .catch(err => enqueueSnackbar(`Failed to create package ${pckg.name}`, SnackbarVariants.ERROR))

    setButtonsDisabled(false)
  }

  const updatePackage = async () => {
    setButtonsDisabled(true)

    let pckg = {
      id: selectedPackage.id,
      createdBy: selectedPackage.createdBy,
      name: submittalInfo.projectName,
      template: submittalInfo.template,
      products: Object.keys(productIdMap).map(id => ({ id: id, order: productIdMap[id].order })),
      selectedDocuments: Object.keys(selectedDocs).reduce((list, obj) => {
        list.push({
          id: obj,
          order: parseInt(selectedDocs[obj].order)
        })
        return list
      }, [])
    }

    await PackagesApi.updatePackage(instance, accounts, pckg)
      .then(response => enqueueSnackbar(`Successfully updated package ${pckg.name}`, SnackbarVariants.SUCCESS))
      .catch(err => enqueueSnackbar(`Failed to update package ${pckg.name}`, SnackbarVariants.ERROR))

    localStorage.setItem('selectedPackage', JSON.stringify(pckg))

    setButtonsDisabled(false)
  }

  const cleanUpFromGroups = async packages => {
    //remove reference to all docs from groups
    for (var packg of packages) {
      for (var group of groupsData) {
        if (group.packages && group.packages.includes(packg.id)) {
          await GroupsApi.removeGroupPackageOverride(instance, accounts, packg.id, group.id)
        }
      }
    }
  }

  const deletePackage = async () => {
    setButtonsDisabled(true)
    await cleanUpFromGroups([selectedPackage])
      .then(
        async () =>
          await PackagesApi.deletePackage(instance, accounts, selectedPackage.id)
            .then(response => enqueueSnackbar(`Successfully deleted package ${selectedPackage.name}`, SnackbarVariants.SUCCESS))
            .catch(err => enqueueSnackbar(`Failed to delete package ${selectedPackage.name}`, SnackbarVariants.ERROR))
      )
      .catch(err =>
        enqueueSnackbar(`Failed to clean package ${selectedPackage.name} references from groups`, SnackbarVariants.ERROR)
      )

    setButtonsDisabled(false)
    handleClose()
  }

  const handleClose = () => {
    setSelectedDocs({})
    setTitlePage(null)

    setSubmittalInfo(DEFAULT_SUBMITTAL_INFO)
    if (titlePage) {
      URL.revokeObjectURL(titlePage)
      setTitlePage(null)
    }
    localStorage.removeItem('selectedPackage')
    clearAllCache()
    setActiveStep(0)
    window.close()
  }

  return (
    <Box sx={{ padding: '0px 15px' }}>
      <>
        {activeStep === STEPS.length ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}></Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: '100%' }}>
            <PrimaryButton
              id='backBundleButton'
              style={{ width: '100px' }}
              onClick={handleBack}
              disabled={activeStep === STEPS.length - 1 ? buttonsDisabled : activeStep === 0}
            >
              Back
            </PrimaryButton>

            <HasAccess
              env={env}
              allowedRoles={['Sales Admin', 'Dealer', 'Packages Admin']}
              unauthorizedControl={<Box sx={{ flex: '1 1 auto' }} />}
            >
              <Box sx={{ flex: 'auto' }} />

              {(selectedPackage &&
                (account.idTokenClaims.roles.includes('Sales Admin') ||
                  account.idTokenClaims.roles.includes('Packages Admin'))) ||
              (selectedPackage && account.localAccountId === selectedPackage.createdBy) ? (
                <>
                  {activeStep <= 1 ? (
                    <>
                      <DeleteButton
                        id='deletePackageButton'
                        style={{ minWidth: '100px', marginRight: '15px' }}
                        onClick={deletePackage}
                        disabled={buttonsDisabled}
                      >
                        Delete Package
                      </DeleteButton>
                      <SuccessButton
                        id='updatePackageButton'
                        style={{ minWidth: '100px', marginRight: '15px' }}
                        onClick={updatePackage}
                        disabled={
                          (activeStep === 1 && Object.keys(selectedDocs).length === 0) ||
                          submittalInfo.projectName === '' ||
                          buttonsDisabled
                        }
                      >
                        Update Package
                      </SuccessButton>
                    </>
                  ) : null}
                </>
              ) : (
                <SuccessButton
                  id='createPackageButton'
                  style={{ minWidth: '100px', marginRight: '15px' }}
                  onClick={createPackage}
                  disabled={Object.keys(selectedDocs).length === 0 || submittalInfo.projectName === '' || buttonsDisabled}
                >
                  Create Package
                </SuccessButton>
              )}
              <Box sx={{ flex: 'auto' }} />
            </HasAccess>
            <PrimaryButton id='nextBundleButton' style={{ minWidth: '100px' }} onClick={handleNext} disabled={deactivateNext}>
              {activeStep === STEPS.length - 1 ? 'Download Bundle PDF' : 'Next'}
            </PrimaryButton>
          </Box>
        )}
      </>

      <Card sx={{ padding: '15px', marginTop: '15px' }}>
        <Stepper activeStep={activeStep}>
          {STEPS.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <div style={{ marginTop: '15px' }}>
          {
            {
              0: (
                <CreateOpportunityTitlePdf
                  titlePage={titlePage}
                  setTitlePage={setTitlePage}
                  productIdMap={productIdMap}
                  setProductIdMap={setProductIdMap}
                  submittalInfo={submittalInfo}
                  setSubmittalInfo={setSubmittalInfo}
                  titlePageIncludes={titlePageIncludes}
                  productDocMap={productDocMap}
                  setTitlePageIncludes={setTitlePageIncludes}
                  htmlRef={htmlRef}
                />
              ),
              1: (
                <SelectOpportunityDocuments
                  productIdMap={productIdMap}
                  setProductIdMap={setProductIdMap}
                  selectedDocs={selectedDocs}
                  isLoading={false}
                  setSelectedDocs={setSelectedDocs}
                  productDocMap={productDocMap}
                  languageOptions={LANGUAGE_OPTIONS}
                  selectedLanguages={selectedLanguages}
                  setSelectedLanguages={setSelectedLanguages}
                  docMap={DEFAULT_DOC_ORDER}
                  categoriesMap={categoriesMap}
                />
              ),
              2: <DownloadOpportunityDocuments selectedDocs={selectedDocs} docMap={DEFAULT_DOC_ORDER} />,
              3: <VerifyModal warningModal={warningModal} setWarningModal={setWarningModal} />,

              '': <div>Error: Try refreshing page</div>
            }[activeStep]
          }
        </div>
      </Card>
    </Box>
  )
}
