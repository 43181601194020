import { CenteredDiv, WtxColors } from '@wavetronix/common-components'
import { ProductTableSection, NotesExclusionsSection } from './TemplateSections'

export const FDOTMATRIXADVANCE_TITLE_INFO = ['projectName', 'preparedByUser', 'dealerAccount']

export default function FDOTMatrixAdvance({ htmlRef, submittalInfo, productIdMap, titlePageIncludes }) {
  return (
    <div ref={htmlRef}>
      {titlePageIncludes.titlePage ? (
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
            breakInside: 'avoid',
            pageBreakInside: 'avoid'
          }}
        >
          <CenteredDiv>
            <img src='/templates/wavetronix_logo_grey_gold.jpg' alt='Wavetronix' width='200px' />
          </CenteredDiv>
          <div style={{ position: 'relative', minHeight: '995px' }}>
            <div style={{ display: 'flex' }}>
              <img src='/templates/matrix_advance1.jpg' alt='Error' width='48%' />
              <div style={{ width: '4%' }} />
              <img src='/templates/matrix_advance2.jpg' alt='Error' width='48%' />
            </div>

            <div>
              <CenteredDiv>
                <h1 style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold', marginBottom: '2px' }}>SmartSensor Matrix & Advance</h1>
              </CenteredDiv>

              <CenteredDiv>
                <h1 style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold', marginBottom: '2px', fontSize: '16px' }}>
                  FDOT APL# 660-018-004 (SmartSensor Matrix)
                </h1>
              </CenteredDiv>
              <CenteredDiv>
                <h1 style={{ whiteSpace: 'pre-wrap', fontWeight: 'bold', marginBottom: '2px', fontSize: '16px' }}>
                  FDOT APL# 660-026-002 (SmartSensor Advance)
                </h1>
              </CenteredDiv>
              <p style={{ fontSize: '14px' }}>
                Please see attached cut sheets for the SmartSensor Matrix & Advance. Let us know if you have any questions by
                replying to our Florida team email: florida@wavetronix.com
              </p>

              <p style={{ whiteSpace: 'pre-wrap', fontSize: '14px' }}>Project Name: {submittalInfo.projectName}</p>
            </div>

            <div style={{ position: 'absolute', bottom: 30, left: 0, width: '100%' }}>
              {submittalInfo.preparedByUser && submittalInfo.preparedByUser.fullname !== '' ? (
                <div style={{ whiteSpace: 'pre-wrap' }}>
                  <p>Thank you,</p>
                  <div>{submittalInfo.preparedByUser.fullname}</div>
                  {submittalInfo.preparedByUser.title !== '' ? <div>{submittalInfo.preparedByUser.title}</div> : null}
                  {submittalInfo.dealerAccount.phonenumber !== '' ? (
                    <div>Office: {submittalInfo.dealerAccount.phonenumber}</div>
                  ) : null}
                  {submittalInfo.preparedByUser.phonenumber !== '' ? (
                    <div>Cell: {submittalInfo.preparedByUser.phonenumber}</div>
                  ) : null}
                  {submittalInfo.dealerAccount.email !== '' ? <div>{submittalInfo.dealerAccount.email}</div> : null}
                </div>
              ) : null}
            </div>
            <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <hr
                style={{
                  color: WtxColors.GOLD,
                  backgroundColor: WtxColors.GOLD
                }}
              />
              <div style={{ fontSize: '12px', textAlign: 'center' }}>
                1827 W 650 N, Springville, UT 84663, US • (801) 734-7200 • www.wavetronix.com{' '}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ProductTableSection titlePageIncludes={titlePageIncludes} productIdMap={productIdMap} />
      <NotesExclusionsSection titlePageIncludes={titlePageIncludes} submittalInfo={submittalInfo} />
    </div>
  )
}
