export default function parseFileName(name) {
  if (name) {
    var fileNameType = name.split('.')
    if (fileNameType.length === 1 || (fileNameType.length === 2 && fileNameType[0] === '')) {
      return name
    } else {
      return fileNameType[0]
    }
  }
}

export const containsSubstring = (original, substring) => {
  if (original && substring) {
    if (!String.prototype.includes) {
      return original.toLowerCase().indexOf(substring.toLowerCase()) !== -1
    } else {
      return original.toLowerCase().includes(substring.toLowerCase())
    }
  } else {
    return false
  }
}

const VIDEO_TYPES = {
  mp4: 'video/mp4',
  mov: 'video/mov',
  avi: 'video/avi',
  wmv: 'video/wmv',
  avchd: 'video/avchd',
  webm: 'video/webm',
  flv: 'video/flv'
}

export function getVideoType(name) {
  var result = { isSuccess: false }
  if (name && name.includes('.')) {
    var fileNameType = name.split('.')
    if (fileNameType.length === 2 && VIDEO_TYPES[fileNameType[1].toLowerCase()]) {
      result = {
        isSuccess: true,
        type: VIDEO_TYPES[fileNameType[1].toLowerCase()]
      }
    }
  }
  return result
}
