import { PrimaryButton } from '@wavetronix/common-components'
import React from 'react'
import { useState, useEffect } from 'react'

export default function FileUploader(props) {
  const [fileName, setFileName] = useState(props.prompt)
  const hiddenFileInput = React.useRef(null)

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  useEffect(() => {
    if (props.clear === true) {
      setFileName(props.prompt)
      const input = document.getElementById('fileuploadform')
      input.reset()
    }
  }, [props.clear, props.prompt])

  const handleChange = event => {
    const fileUploaded = event.target.files[0]
    if (fileUploaded) {
      setFileName(fileUploaded.name)
    } else {
      setFileName(props.prompt)
    }

    props.handleFile(fileUploaded)
  }

  return (
    <form id='fileuploadform' style={props.style}>
      <PrimaryButton
        id='fileUploadButton'
        style={{ width: '100%', marginBottom: 15 }}
        onClick={handleClick}
        disabled={props.disabled}
      >
        {fileName}
      </PrimaryButton>
      <input
        type='file'
        id='file'
        ref={hiddenFileInput}
        accept={props.fileTypes}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </form>
  )
}
