import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import PackagesApi from '../../api/PackagesApi'
import { CustomDataGrid, CenteredDiv, ErrorMessage, PersistantFilterDiv } from '@wavetronix/common-components'
import PackagesFilterDrawer, { DEFAULT_PACKAGES_FILTER } from '../drawers/PackagesFilterDrawer'
import { clearAllCache } from '../../utils/cacheUtils'
import { useParams } from 'react-router-dom'
import useProductDocumentMap from '../customhooks/useProductDocumentMap'
import DocumentsApi from '../../api/DocumentsApi'

export default function PackageManagementPage({ productTypes, categoriesMap, instance, accounts }) {
  const { unitId } = useParams()
  const [filter, setFilter] = useState(DEFAULT_PACKAGES_FILTER)

  const { data: documents } = useQuery({
    queryKey: ['mydocuments'],
    queryFn: async () => {
      let result = await DocumentsApi.getMyDocuments(instance, accounts, unitId).then(res =>
        res.sort((a, b) => {
          if (a.order > b.order) {
            return 1
          } else {
            return -1
          }
        })
      )
      return result
    }
  })

  const productDocMap = useProductDocumentMap(productTypes, documents, categoriesMap)

  const columns = [
    {
      headerName: 'Package Name',
      field: 'name',
      flex: 1
    }
  ]

  const {
    data: packages,
    isLoading: packagesIsLoading,
    error: packagesError
  } = useQuery({ queryKey: ['packages'], queryFn: async () => await PackagesApi.getMyPackages(instance, accounts) })

  const generateProductIdMap = async pckg => {
    //Set up productIdMap based on package info
    let map = {}
    let packageProducts = pckg.products.reduce((map, obj) => {
      map[obj.id] = obj.order
      return map
    }, {})
    for (let prod of productTypes) {
      if (packageProducts[prod.id]) {
        map[prod.id] = {
          count: 1,
          documents: { ...productDocMap[prod.id].documents },
          name: prod.name,
          productnumber: prod.productItemNumbers.join(' OR '),
          order: packageProducts[prod.id]
        }
      }
    }
    localStorage.setItem('productIdMap', JSON.stringify(map))
    localStorage.setItem('selectedPackage', JSON.stringify(pckg))
  }

  if (packagesError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={packagesError} />
      </CenteredDiv>
    )
  }

  return (
    <>
      <div style={{ display: 'flex' }}>
        <PersistantFilterDiv
          page={
            <div style={{ marginLeft: '24px', marginTop: '15px' }}>
              {packagesIsLoading ? (
                <CenteredDiv>
                  <CircularProgress />
                </CenteredDiv>
              ) : packages && packages.length === 0 ? (
                <CenteredDiv>No packages available.</CenteredDiv>
              ) : (
                <CustomDataGrid
                  rows={packages ? packages : []}
                  isLoading={packagesIsLoading}
                  columns={columns}
                  onRowClick={async doc => {
                    localStorage.removeItem('selectedPackage')

                    clearAllCache()

                    await generateProductIdMap(doc.row).then(() => window.open(`/manualpackage/${unitId}`, '_blank'))
                  }}
                  cursor='pointer'
                />
              )}
            </div>
          }
          drawer={<PackagesFilterDrawer filter={filter} setFilter={setFilter} />}
          resetFilter={() => setFilter(DEFAULT_PACKAGES_FILTER)}
        />
      </div>
    </>
  )
}
