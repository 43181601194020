import { useMemo, useEffect } from 'react'
import { Stepper, Step, StepLabel, Box, Card } from '@mui/material'
import { PrimaryButton, CenteredDiv, ErrorMessage } from '@wavetronix/common-components'
import CreateOpportunityTitlePdf from '../controls/CreateOpportunityTitlePdf'
import SelectOpportunityDocuments from '../controls/SelectOpportunityDocuments'
import DownloadOpportunityDocuments from '../controls/DownloadOpportunityDocuments'
import DataverseConnectorApi from '../../api/DataverseConnectorApi'
import { useQuery } from '@tanstack/react-query'
import VerifyModal from './VerifyModal'
import { STEPS } from './BundleWrapperModal'

export default function OpportunityBundleModal({
  productDocMap,
  selectedDocs,
  setSelectedDocs,
  activeStep,
  setActiveStep,
  submittalInfo,
  setSubmittalInfo,
  productIdMap,
  setProductIdMap,
  titlePageIncludes,
  setTitlePageIncludes,
  buttonsDisabled,
  setButtonsDisabled,
  titlePage,
  setTitlePage,
  selectedLanguages,
  setSelectedLanguages,
  warningModal,
  setWarningModal,
  LANGUAGE_OPTIONS,
  DEFAULT_DOC_ORDER,
  htmlRef,
  deactivateNext,
  handleNext,
  handleBack,
  categoriesMap
}) {
  const opportunityid = useMemo(() => {
    const storedData = localStorage.getItem('opportunityid')
    return JSON.parse(storedData)
  }, [])

  const opportunity = useMemo(() => {
    const storedData = localStorage.getItem('opportunity')
    try {
      return JSON.parse(storedData)
    } catch (e) {
      console.error(e) // error in the above string (in this case, yes)!
    }
    return null
  }, [])

  const { data, isLoading, error } = useQuery({
    queryKey: ['opportunityproducts', opportunityid],
    queryFn: async () =>
      await DataverseConnectorApi.getOpportunityProductsById(opportunityid).then(res => {
        return res.value
      }),
    enabled: !!opportunityid && opportunityid !== '' // Set 'enabled' to false if the parameter is null or an empty string
  })

  const {
    data: products,
    isLoading: productsIsLoading,
    error: productsError
  } = useQuery({
    queryKey: ['existingproducts', data],
    queryFn: async () =>
      await DataverseConnectorApi.getProductsThroughQueryList(data.map(d => d._productid_value)).then(res => {
        return res.value
      }),
    enabled: !!data && data.length > 0 // Set 'enabled' to false if the parameter is null or an empty string
  })

  const { data: bundleData } = useQuery({
    queryKey: ['opportunitybundle'],
    queryFn: async () => {
      let bundleInfo = {}

      for (let d of data) {
        await DataverseConnectorApi.getValueByEq(
          'pt_opportunitybundles',
          'pt_opportunitybundleid',
          d['_pt_opportunitybundleid_value'] //_pt_opportunitybundleid_value from opportunityproducts
        ).then(res => {
          bundleInfo[d['_pt_opportunitybundleid_value']] = res.value
          return res.value
        })
      }
      return bundleInfo
    },
    enabled: !!data && data.length > 0 // Set 'enabled' to false if the parameter is null or an empty string
  })

  useEffect(() => {
    let productMap = {}
    let fullProductMap = {}

    if (data && products && productDocMap && bundleData) {
      let sortedData = [...data]

      sortedData.sort((a, b) => {
        let aBundle = bundleData[a['_pt_opportunitybundleid_value']][0]
        let bBundle = bundleData[b['_pt_opportunitybundleid_value']][0]
        if (aBundle.pt_sequencenumber === bBundle.pt_sequencenumber) {
          return a.sequencenumber > b.sequencenumber ? 1 : -1
        } else {
          return aBundle.pt_sequencenumber > bBundle.pt_sequencenumber ? 1 : -1
        }
      })

      for (let product of products) {
        productMap[product.productid] = { ...product, documents: {}, count: 0 }
        for (let productData of Object.values(productDocMap)) {
          if (productData.productInfo.productItemNumbers.includes(product.productnumber)) {
            productMap[product.productid] = {
              ...productMap[product.productid],
              documents: { ...productMap[product.productid]['documents'], ...productData.documents }
            }
          }
        }
      }

      let count = 1
      let fullCount = 1
      for (let d of sortedData) {
        fullProductMap[d.opportunityproductid] = {
          ...productMap[d._productid_value],
          count: d.quantity ? d.quantity : 0,
          // bidItemNumber: d['pt_biditemnumber'], TODO: BID NUMBER
          order: fullCount++
        }

        if (productMap[d._productid_value]) {
          productMap[d._productid_value] = {
            ...productMap[d._productid_value],
            count: d.quantity ? productMap[d._productid_value].count + d.quantity : 0,
            order: productMap[d._productid_value].order ? productMap[d._productid_value].order : count++
          }
        } else {
          console.log(`Error: Product Id ${d._productid_value} not in product map.`)
        }
      }
    }
    setProductIdMap(productMap)
  }, [data, products, productDocMap, selectedLanguages, bundleData, setProductIdMap])

  return (
    <Box sx={{ padding: '0px 15px' }}>
      <>
        {activeStep === STEPS.length ? (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}></Box>
        ) : (
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, width: '100%' }}>
            <PrimaryButton
              id='backBundleButton'
              style={{ width: '100px' }}
              onClick={handleBack}
              disabled={activeStep === STEPS.length - 1 ? buttonsDisabled : activeStep === 0}
            >
              Back
            </PrimaryButton>
            <Box sx={{ flex: '1 1 auto' }} />

            <PrimaryButton id='nextBundleButton' style={{ minWidth: '100px' }} onClick={handleNext} disabled={deactivateNext}>
              {activeStep === STEPS.length - 1 ? 'Download Bundle PDF' : 'Next'}
            </PrimaryButton>
          </Box>
        )}
      </>
      <Card sx={{ padding: '15px', marginTop: '15px' }}>
        <Stepper activeStep={activeStep}>
          {STEPS.map((label, index) => {
            const stepProps = {}
            const labelProps = {}
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        <div style={{ marginTop: '15px' }}>
          {error || productsError ? (
            <CenteredDiv>
              <ErrorMessage error={error} />
              <ErrorMessage error={productsError} />
            </CenteredDiv>
          ) : (
            {
              0: (
                <CreateOpportunityTitlePdf
                  opportunity={opportunity}
                  productTableEditable={true}
                  titlePage={titlePage}
                  setTitlePage={setTitlePage}
                  productIdMap={productIdMap}
                  setProductIdMap={setProductIdMap}
                  submittalInfo={submittalInfo}
                  setSubmittalInfo={setSubmittalInfo}
                  setTitlePageIncludes={setTitlePageIncludes}
                  productDocMap={productDocMap}
                  titlePageIncludes={titlePageIncludes}
                  htmlRef={htmlRef}
                />
              ),
              1: (
                <SelectOpportunityDocuments
                  productTableEditable={true}
                  productIdMap={productIdMap}
                  setProductIdMap={setProductIdMap}
                  selectedDocs={selectedDocs}
                  isLoading={productsIsLoading || isLoading}
                  setSelectedDocs={setSelectedDocs}
                  productDocMap={productDocMap}
                  languageOptions={LANGUAGE_OPTIONS}
                  selectedLanguages={selectedLanguages}
                  setSelectedLanguages={setSelectedLanguages}
                  docMap={DEFAULT_DOC_ORDER}
                  categoriesMap={categoriesMap}
                />
              ),
              2: <DownloadOpportunityDocuments selectedDocs={selectedDocs} docMap={DEFAULT_DOC_ORDER} />,
              3: <VerifyModal warningModal={warningModal} setWarningModal={setWarningModal} />,
              '': <div>Error: Try refreshing page</div>
            }[activeStep]
          )}
        </div>
      </Card>
    </Box>
  )
}
