import { TextField, CircularProgress, Tooltip, IconButton } from '@mui/material'
import { CenteredDiv } from '@wavetronix/common-components'
import { useMemo } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'

export default function ProductTable({ productIdMap, setProductIdMap, editable, removeProduct }) {
  const hasZeroLegend = useMemo(() => {
    if (productIdMap) {
      return (
        Object.values(productIdMap).some(product => {
          return product.count === '0' || product.count === 0
        }) && !editable
      )
    }
  }, [productIdMap, editable])

  if (productIdMap && Object.keys(productIdMap).length > 0) {
    return (
      <>
        {hasZeroLegend === true ? <div style={{ paddingBottom: '5px' }}>(~) Quantity to be determined by customer.</div> : null}
        <table style={{ borderCollapse: 'collapse', width: '100%', fontSize: '12px' }}>
          <tbody>
            <tr>
              <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', width: '13%' }}>Qty</th>
              <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', width: '25%' }}>Part Number</th>
              <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', width: '50%' }}>Description</th>
              {/* <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', width: '12%' }}>Bid Number</th>              // TODO: BID NUMBER
               */}
              {editable ? (
                <th style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', width: '12%' }}>Order</th>
              ) : null}
            </tr>
            {Object.entries(productIdMap)
              .sort(([aid, aproduct], [bid, bproduct]) => (aproduct.order > bproduct.order ? 1 : -1))
              .map(([id, product], index) => {
                return (
                  <tr key={index}>
                    <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', whiteSpace: 'pre-wrap' }}>
                      {editable ? (
                        <TextField
                          inputProps={{ min: 0 }}
                          type='number'
                          sx={{ width: '100%' }}
                          size='small'
                          value={product.count}
                          onChange={e => setProductIdMap(map => ({ ...map, [id]: { ...map[id], count: e.target.value } }))}
                        />
                      ) : product.count === '0' || product.count === 0 ? (
                        '~'
                      ) : (
                        product.count
                      )}
                    </td>
                    <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', whiteSpace: 'pre-wrap' }}>
                      {product.productnumber}
                    </td>
                    <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', whiteSpace: 'pre-wrap' }}>
                      {product.name}
                    </td>
                    {/* <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', whiteSpace: 'pre-wrap' }}>
                      {product.bidItemNumber} TODO: BID NUMBER
                    </td> */}
                    {editable ? (
                      <td style={{ border: '1px solid #dddddd', textAlign: 'left', padding: '8px', whiteSpace: 'pre-wrap' }}>
                        <TextField
                          id={`${id}ProductOrderTextField`}
                          inputProps={{ min: 1 }}
                          type='number'
                          sx={{ width: '100%' }}
                          size='small'
                          value={product.order}
                          onChange={e => setProductIdMap(map => ({ ...map, [id]: { ...map[id], order: e.target.value } }))}
                        />{' '}
                      </td>
                    ) : null}
                    {editable ? (
                      <td style={{ border: 'transparent', textAlign: 'left', padding: '8px', whiteSpace: 'pre-wrap' }}>
                        <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                          <Tooltip title='Remove'>
                            <IconButton id={`${id}ProductRemoveButton`} onClick={() => removeProduct({ id: id, ...product })}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </td>
                    ) : null}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </>
    )
  } else {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
}
