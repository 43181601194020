import ProductDocumentBundler from './ProductDocumentBundler'
import useProductDocumentMap from '../customhooks/useProductDocumentMap'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import DocumentsApi from '../../api/DocumentsApi'

export default function SubmittalsManagementPage({ productTypes, categoriesMap, instance, accounts }) {
  const { unitId } = useParams()

  const { data: documents } = useQuery({
    queryKey: ['mydocuments'],
    queryFn: async () => {
      let result = await DocumentsApi.getMyDocuments(instance, accounts, unitId).then(res =>
        res.sort((a, b) => {
          if (a.order > b.order) {
            return 1
          } else {
            return -1
          }
        })
      )
      return result
    }
  })

  const productDocMap = useProductDocumentMap(productTypes, documents, categoriesMap)

  return (
    <>
      <div style={{ display: 'flex' }}>
        <ProductDocumentBundler productDocMap={productDocMap} />
      </div>
    </>
  )
}
