import { TextField } from '@mui/material'
import { useWtxLocalization } from '@wavetronix/common-components'
import { containsSubstring } from '../../utils/stringUtils'

export const DEFAULT_PACKAGES_FILTER = {
  name: ''
}

export const filterPackages = (filter, packages) => {
  return packages ? packages.filter(u => containsSubstring(u.name, filter.name) || filter.name === '') : []
}

export default function PackagesFilterDrawer({ filter, setFilter }) {
  let localizedStrings = useWtxLocalization()
  return (
    <>
      <TextField
        id='filterNameTextField'
        style={{ width: '100%' }}
        label={localizedStrings.name}
        size='small'
        value={filter.name}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
      />
    </>
  )
}
