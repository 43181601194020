import { TextField, Stack } from '@mui/material'
import { CustomAccordion, WtxColors } from '@wavetronix/common-components'
import PendingIcon from '@mui/icons-material/Pending'
import SearchOffIcon from '@mui/icons-material/SearchOff'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

const classes = {
  accordionStyle: {
    padding: '5px'
  },
  textFieldStyle: {
    width: '100%',
    marginTop: '15px'
  }
}

function GenerateQueryStateIcon(isLoading, error, notFound) {
  if (isLoading) {
    return <PendingIcon id='pendingIcon' sx={{ color: WtxColors.IQ_BLUE }} />
  } else if (error) {
    return <ErrorIcon id='errorIcon' sx={{ color: WtxColors.INNOVATION_RED }} />
  } else if (notFound === true) {
    return <SearchOffIcon id='searchOffIcon' sx={{ color: WtxColors.ASPHALT }} />
  } else {
    return <CheckCircleIcon id='checkCircleIcon' sx={{ color: WtxColors.GROWTH_GREEN }} />
  }
}

export function SimpleInfoSection({ title, simpleInfo, updateSubmittalInfo, infoValue, isLoading, error, notFound }) {
  return (
    <CustomAccordion
      title={
        <Stack direction='row' spacing={2}>
          <div>{GenerateQueryStateIcon(isLoading, error, notFound)}</div>
          <div>
            {title}
            {simpleInfo && simpleInfo !== '' ? ` - ${simpleInfo}` : ''}
          </div>
        </Stack>
      }
      detailStyle={classes.accordionStyle}
      style={{ marginTop: '15px' }}
    >
      <TextField
        id={`${title}NameSimpleInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Name'
        value={simpleInfo ? simpleInfo : ''}
        size='small'
        onChange={e => updateSubmittalInfo(info => ({ ...info, [infoValue]: e.target.value }))}
      />
    </CustomAccordion>
  )
}

export function UserInfoSection({ title, user, updateSubmittalInfo, infoValue, isLoading, error, notFound }) {
  return (
    <CustomAccordion
      title={
        <Stack direction='row' spacing={2}>
          <div>{GenerateQueryStateIcon(isLoading, error, notFound)}</div>
          <div>
            {title}
            {user.fullname && user.fullname !== '' ? ` - ${user.fullname}` : ''}
          </div>
        </Stack>
      }
      detailStyle={classes.accordionStyle}
      style={{ marginTop: '15px' }}
    >
      <TextField
        id={`${title}NameUserInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Name'
        value={user.fullname}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], fullname: e.target.value } }))
        }
      />
      <TextField
        id={`${title}EmailUserInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Email'
        value={user.email}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], email: e.target.value } }))
        }
      />
      <TextField
        id={`${title}TitleUserInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Title'
        value={user.title}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], title: e.target.value } }))
        }
      />
      <TextField
        id={`${title}PhoneNumUserInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Phone Number'
        value={user.phonenumber}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], phonenumber: e.target.value } }))
        }
      />
    </CustomAccordion>
  )
}

export function AccountInfoSection({ title, account, updateSubmittalInfo, infoValue, isLoading, error, notFound }) {
  return (
    <CustomAccordion
      title={
        <Stack direction='row' spacing={2}>
          <div>{GenerateQueryStateIcon(isLoading, error, notFound)}</div>
          <div>
            {title}
            {account.name && account.name !== '' ? ` - ${account.name}` : ''}
          </div>
        </Stack>
      }
      detailStyle={classes.accordionStyle}
      style={{ marginTop: '15px' }}
    >
      <TextField
        id={`${title}NameAccountInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Name'
        value={account.name}
        size='small'
        onChange={e => updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], name: e.target.value } }))}
      />
      <TextField
        id={`${title}EmailAccountInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Email'
        value={account.email ? account.email : ''}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], email: e.target.value } }))
        }
      />
      <TextField
        id={`${title}AddressAccountInfoSectionTextField`}
        disabled={isLoading}
        multiline
        style={classes.textFieldStyle}
        label='Address'
        value={account.address}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], address: e.target.value } }))
        }
      />
      <TextField
        id={`${title}PhoneNumAccountInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Phone Number'
        value={account.phonenumber}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], phonenumber: e.target.value } }))
        }
      />
    </CustomAccordion>
  )
}

export function ContactInfoSection({ title, contact, updateSubmittalInfo, infoValue, isLoading, error, notFound }) {
  return (
    <CustomAccordion
      title={
        <Stack direction='row' spacing={2}>
          <div>{GenerateQueryStateIcon(isLoading, error, notFound)}</div>
          <div>
            {title}
            {contact.fullname && contact.fullname !== '' ? ` - ${contact.fullname}` : ''}
          </div>
        </Stack>
      }
      detailStyle={classes.accordionStyle}
      style={{ marginTop: '15px' }}
    >
      <TextField
        id={`${title}NameContactInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Name'
        value={contact.fullname}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], fullname: e.target.value } }))
        }
      />
      <TextField
        id={`${title}EmailContactInfoSectionTextField`}
        disabled={isLoading}
        multiline
        style={classes.textFieldStyle}
        label='Email'
        value={contact.email}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], email: e.target.value } }))
        }
      />
      <TextField
        id={`${title}PhoneNumContactInfoSectionTextField`}
        disabled={isLoading}
        style={classes.textFieldStyle}
        label='Phone Number'
        value={contact.phonenumber}
        size='small'
        onChange={e =>
          updateSubmittalInfo(info => ({ ...info, [infoValue]: { ...info[`${infoValue}`], phonenumber: e.target.value } }))
        }
      />
    </CustomAccordion>
  )
}
