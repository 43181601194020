import { CenteredDiv, WtxColors } from '@wavetronix/common-components'
import { ProductTableSection, NotesExclusionsSection } from './TemplateSections'
import dayjs from 'dayjs'

export const GENERIC2_TITLE_INFO = ['projectName', 'preparedByUser', 'dealerAccount']

export default function GenericTitlePage2({ htmlRef, submittalInfo, productIdMap, titlePageIncludes }) {
  return (
    <div ref={htmlRef}>
      {titlePageIncludes.titlePage ? (
        <div
          style={{
            paddingTop: '20px',
            paddingLeft: '15px',
            paddingRight: '15px',
            paddingBottom: '15px',
            breakInside: 'avoid',
            pageBreakInside: 'avoid'
          }}
        >
          <img src='/templates/wavetronix_logo_grey_gold.jpg' alt='Wavetronix' width='200px' />
          <div style={{ position: 'relative', height: '995px' }}>
            <div>
              <CenteredDiv>
                <h1 style={{ whiteSpace: 'pre-wrap' }}>Submittal Package</h1>
              </CenteredDiv>
              <CenteredDiv>
                <div style={{ whiteSpace: 'pre-wrap', maxWidth: '400px', textAlign: 'center' }}>
                  Project: {submittalInfo.projectName}
                </div>
              </CenteredDiv>
            </div>

            {submittalInfo.preparedByUser && submittalInfo.preparedByUser.fullname !== '' ? (
              <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
                <div>Prepared on {dayjs(submittalInfo.date).format('MMMM D, YYYY')}</div>
                <div>by: {submittalInfo.preparedByUser.fullname}</div>
                {submittalInfo.preparedByUser.title !== '' ? <div>{submittalInfo.preparedByUser.title}</div> : null}
                {submittalInfo.dealerAccount.phonenumber !== '' ? (
                  <div>Office: {submittalInfo.dealerAccount.phonenumber}</div>
                ) : null}
                {submittalInfo.preparedByUser.phonenumber !== '' ? (
                  <div>Cell: {submittalInfo.preparedByUser.phonenumber}</div>
                ) : null}
                {submittalInfo.dealerAccount.email !== '' ? <div>{submittalInfo.dealerAccount.email}</div> : null}
              </div>
            ) : null}

            <div style={{ whiteSpace: 'pre-wrap', textAlign: 'center', marginTop: '20px' }}>
              <div>This submittal includes the highlighted components only.</div>

              <div>
                Thank you for the opportunity to provide this submittal package. Wavetronix equipment listed below and detailed in
                this package are for this project and are based on the documents created by WSP dated{' '}
                {dayjs(submittalInfo.date).format('M/D/YYYY')}.
              </div>
            </div>
            <div style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
              <hr
                style={{
                  color: WtxColors.GOLD,
                  backgroundColor: WtxColors.GOLD
                }}
              />
              <div style={{ fontSize: '12px', textAlign: 'center' }}>
                1827 W 650 N, Springville, UT 84663, US • (801) 734-7200 • www.wavetronix.com{' '}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <ProductTableSection titlePageIncludes={titlePageIncludes} productIdMap={productIdMap} />
      <NotesExclusionsSection titlePageIncludes={titlePageIncludes} submittalInfo={submittalInfo} />
    </div>
  )
}
