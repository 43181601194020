import { TextField, Box } from '@mui/material'
import { CustomSelect, useWtxLocalization, CustomCheckbox } from '@wavetronix/common-components'
import { containsSubstring } from '../../utils/stringUtils'

export const DEFAULT_PRODUCT_FILTER = {
  name: '',
  description: '',
  family: 'All',
  itemNumber: '',
  categoryType: 'All',
  showIsArchived: false
}

const TYPE_OPTIONS = ['All', 'Product', 'Accessory']

export const filterCategories = (filter, categories, displayedProductFamilies) =>
  categories
    ? categories.filter(
        c =>
          (filter.categoryType === 'Product' ? displayedProductFamilies.includes(c.family) : true) &&
          (filter.showIsArchived === true || (filter.showIsArchived === false && c.isArchived === false)) &&
          (filter.categoryType !== 'All' ? filter.categoryType === c.categoryType : TYPE_OPTIONS.includes(c.categoryType)) &&
          (containsSubstring(c.name, filter.name) || filter.name === '') &&
          (filter.family === c.family || filter.family === 'All') &&
          (c.productItemNumbers.filter(itemNumber => containsSubstring(itemNumber, filter.itemNumber)).length > 0 ||
            filter.itemNumber === '')
      )
    : []

export const filterCrmCategories = (filter, categories) =>
  categories
    ? categories.filter(
        c =>
          (filter.showIsArchived === true || (filter.showIsArchived === false && c.isArchived === false)) &&
          (containsSubstring(c.name, filter.name) || filter.name === '') &&
          (filter.family === c.family || filter.family === 'All') &&
          (c.productItemNumbers.filter(itemNumber => containsSubstring(itemNumber, filter.itemNumber)).length > 0 ||
            filter.itemNumber === '')
      )
    : []

export default function CategoriesFilterDrawer({ filter, setFilter, category, categoryFamlies, isCrm = false }) {
  let localizedStrings = useWtxLocalization()

  return (
    <>
      {isCrm === false ? (
        <CustomSelect
          style={{ width: '100%' }}
          label={localizedStrings.productType}
          value={filter.categoryType}
          onChange={e => setFilter(f => ({ ...f, categoryType: e.target.value }))}
          options={TYPE_OPTIONS}
        />
      ) : null}

      <TextField
        id='filterNameTextField'
        style={{ width: '100%', marginTop: '15px' }}
        label={localizedStrings.name}
        size='small'
        value={filter.name}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
      />
      <TextField
        id='filterItemNumberTextField'
        style={{ marginTop: '10px', width: '100%' }}
        label={localizedStrings.productNumber}
        size='small'
        value={filter.itemNumber}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, itemNumber: e.target.value }))}
      />
      {category === 'Product' ? (
        <CustomSelect
          id='filterProductFamilySelect'
          style={{ width: '100%', marginTop: '15px' }}
          label={localizedStrings.productFamily}
          value={filter.family}
          onChange={e => setFilter(f => ({ ...f, family: e.target.value }))}
          options={['All', ...categoryFamlies.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1)).map(p => p.name)]}
        />
      ) : (
        <span />
      )}
      <Box sx={{ display: 'flex', direction: 'row' }}>
        <CustomCheckbox
          id='filterArchivedCheckbox'
          checked={filter.showIsArchived}
          onChange={e => setFilter(f => ({ ...f, showIsArchived: e.target.checked }))}
        />
        <span style={{ paddingTop: '9px' }}>Show Archived</span>
      </Box>
    </>
  )
}
