import { useMsal } from '@azure/msal-react'
import { CircularProgress } from '@mui/material'
import { CenteredDiv, ErrorMessage } from '@wavetronix/common-components'
import React, { useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import DocumentsApi from '../../api/DocumentsApi'
import DocumentsListDisplayPage from './DocumentsListDisplayPage'
import DocumentsTypeDisplayPage from './DocumentsTypeDisplayPage'
import { useCookies } from 'react-cookie'
import { useParams } from 'react-router-dom'

const DOCUMENT_LEVELS = {
  'All Levels': 0,
  Basic: 1,
  Intermediate: 2,
  Advanced: 3
}

export default function CustomerDocumentsPage(props) {
  const { instance, accounts } = useMsal()
  const { unitId } = useParams()
  const [cookies, setCookie] = useCookies(['fromFamily'])

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ['mydocuments'],
    queryFn: async () => {
      let result = await DocumentsApi.getMyDocuments(instance, accounts, unitId).then(res =>
        res.sort((a, b) => {
          if (a.order > b.order) {
            return 1
          } else {
            return -1
          }
        })
      )

      return result
    }
  })

  const options = useMemo(() => {
    if (data) {
      let sortedOptions = Array.from(
        data.reduce((set, doc) => {
          if (!set.has(doc.accessLevel)) {
            set.add(doc.accessLevel)
          }
          return set
        }, new Set())
      ).sort((a, b) => {
        if (DOCUMENT_LEVELS[a] < DOCUMENT_LEVELS[b]) {
          return -1
        } else {
          return 1
        }
      })

      return ['All Levels', ...sortedOptions]
    }
  }, [data])

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }
  if (isLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '100%' }}>
        {props.showFullList ? (
          <DocumentsListDisplayPage
            openCreateModal={() => null}
            styles={props.styles}
            refetch={refetch}
            data={data ? data : []}
            loading={isLoading}
            options={options}
            cookies={cookies}
            setCookie={setCookie}
          />
        ) : (
          <DocumentsTypeDisplayPage
            styles={props.styles}
            state={props.productState}
            setState={props.setProductState}
            cookies={cookies}
            setCookie={setCookie}
          />
        )}
      </div>
    </div>
  )
}
