import CustomerDocumentsPage from './pages/CustomerDocumentsPage'

export default function DocumentsPage(props) {
  return (
    <CustomerDocumentsPage
      styles={props.styles}
      tabState={props.state}
      changeTabState={props.changeTabState}
      showFullList={props.showFullList}
      productState={props.productState}
      setProductState={props.setProductState}
    />
  )
}
