import * as React from 'react'
import CloudDownloadIcon from '../icons/CloudDownloadIcon'
import { useEffect, useRef } from 'react'
import { IconButton, Tooltip } from '@mui/material'
import { GetAppOutlined } from '@mui/icons-material'
import axios from 'axios'

export function DownloadButtonWtx({ url, token, variant, style, fileName, ...props }) {
  const formRef = useRef(null)

  useEffect(() => {
    const handleFormSubmit = async event => {
      event.preventDefault() // Prevent default form submission

      const formData = new FormData(formRef.current) // Capture form data
      formData.append('token', token)
      formData.append('useMime', true)
      try {
        const response = await axios.post(url, formData, {
          responseType: 'blob', // Handle response as a Blob
          headers: { 'Content-Type': 'multipart/form-data', 'Content-Disposition': 'inline; filename="desired.pdf"' }
        })

        const href = URL.createObjectURL(response.data)

        if (fileName.endsWith('.pdf')) {
          window.open(href, '_blank') // Open PDF in a new tab
        }

        // create "a" HTLM element with href to file & click
        const link = document.createElement('a')
        link.href = href
        link.setAttribute('download', fileName) //or any other extension
        document.body.appendChild(link)
        link.click()

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link)

        URL.revokeObjectURL(href)
      } catch (error) {
        console.error('Error downloading PDF:', error)
      }
    }

    // Attach the event listener to the form
    const formElement = formRef.current
    formElement.addEventListener('submit', handleFormSubmit)

    // Cleanup event listener on component unmount
    return () => formElement.removeEventListener('submit', handleFormSubmit)
  }, [url, token, fileName])
  return (
    <form action={url} method='post' ref={formRef} id='downloadButton'>
      {variant === 'icon' ? (
        <Tooltip title={props.tooltip}>
          <IconButton type='submit' name='token' value={token} className='btn-link' color='primary' {...props} size='large'>
            <GetAppOutlined />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={props.tooltip}>
          <IconButton type='submit' name='token' value={token} disableRipple sx={style.buttonStyle} {...props} size='large'>
            <CloudDownloadIcon />
            <div style={{ ...style.downloadLink, marginLeft: 10, marginTop: 5 }}>{fileName}</div>
          </IconButton>
        </Tooltip>
      )}
    </form>
  )
}
