import { CircularProgress, TextField, Box } from '@mui/material'
import { useMsal } from '@azure/msal-react'
import {
  CenteredDiv,
  CustomSelect,
  ErrorMessage,
  useWtxLocalization,
  ListSelector,
  CustomAccordion,
  HasAccess,
  CustomCheckbox
} from '@wavetronix/common-components'
import { useEffect, useState, useMemo } from 'react'
import { useQuery } from '@tanstack/react-query'
import { containsSubstring } from '../../utils/stringUtils'
import CategoriesApi from '../../api/CategoriesApi'
import { env } from '../../index.js'
import { useParams } from 'react-router-dom'

export const DEFAULT_DOCUMENTS_FILTER = {
  name: '',
  accessLevel: 'All Levels',
  productTypes: [],
  documentTypes: [],
  languageTypes: [],
  description: '',
  showHidden: false,
  showOverrides: false,
  showIsArchived: false,
  productId: '',
  workInstruction: ''
}

const baseDocFilter = (doc, filter, displayedProducts) => {
  return (
    (filter.showIsArchived === false ? doc.products.filter(p => displayedProducts.includes(p)).length > 0 : true) &&
    (containsSubstring(doc.fileName, filter.name) || filter.name === '') &&
    (containsSubstring(doc.description, filter.description) || filter.description === '') &&
    (doc.products.filter(p => filter.productTypes.map(fp => fp.id).includes(p)).length > 0 || filter.productTypes.length === 0) &&
    (filter.crmProductTypes
      ? doc.crmProducts.filter(p => filter.crmProductTypes.map(fp => fp.id).includes(p)).length > 0 ||
        filter.crmProductTypes.length === 0
      : true)
  )
}

const nexusDocFilter = (doc, filter) => {
  return (
    (doc.accessLevel === filter.accessLevel || filter.accessLevel === 'All Levels') &&
    (filter.documentTypes.map(df => df.id).includes(doc.documentType) || filter.documentTypes.length === 0) &&
    (doc.language.filter(p => filter.languageTypes.map(fp => fp.id).includes(p)).length > 0 || filter.languageTypes.length === 0)
  )
}

const manufacturingDocFilter = (doc, filter) => {
  return (
    (filter.productId === '' || containsSubstring(doc.productId, filter.productId)) &&
    (filter.workInstruction === '' || containsSubstring(doc.workInstruction, filter.workInstruction))
  )
}

export const filterDocuments = (filter, documents, displayedProducts, unitId) => {
  return documents
    ? documents.filter(d => {
        let unitFilter = false
        let baseFilter = baseDocFilter(d, filter, displayedProducts)
        if (unitId === 'nexus') {
          unitFilter = nexusDocFilter(d, filter)
        } else if (unitId === 'manufacturing') {
          unitFilter = manufacturingDocFilter(d, filter)
        }
        return unitFilter && baseFilter
      })
    : []
}

export default function DocumentsFilterDrawer({ filter, setFilter, options, justDocType = false }) {
  const { instance, accounts } = useMsal()
  const { unitId } = useParams()
  const [documentTypes, setDocumentTypes] = useState([])
  const [languageTypes, setLanguageTypes] = useState([])
  let localizedStrings = useWtxLocalization()

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError
  } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      let categories = await CategoriesApi.getCategories(instance, accounts)
      return categories
    }
  })

  useEffect(() => {
    if (categories) {
      let docTypes = categories
        .filter(c => c.categoryType === 'Document Type')
        .sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
      let langTypes = categories.filter(c => c.categoryType === 'Language')

      setDocumentTypes(docTypes)
      setLanguageTypes(langTypes)

      //set filter default, by first finding if there is a language that matches (should always include doc with matching base prefix) if not then don't set filter
      let browserLang = langTypes.filter(
        l =>
          l.name === window.navigator.language ||
          (l.name.length >= 2 && window.navigator.language.length === 2
            ? l.name.slice(0, 2) === window.navigator.language
            : false) ||
          (l.name.length === 2 && window.navigator.language.length >= 2
            ? window.navigator.language.slice(0, 2) === l.name
            : false)
      )

      if (browserLang.length > 0) {
        setFilter(f => ({ ...f, languageTypes: browserLang }))
      }
    }
  }, [categories, setFilter])

  const productTypes = useMemo(() => {
    let res = []
    if (categories) {
      res =
        filter.showIsArchived === false
          ? categories.filter(c => c.categoryType === 'Product' && c.isArchived === false)
          : categories.filter(c => c.categoryType === 'Product')
    }
    return res.sort((a, b) => (a.listOrder > b.listOrder ? 1 : -1))
  }, [filter, categories])

  const unitComponents = {
    name: (
      <TextField
        id='filterNameTextField'
        style={{ width: '100%' }}
        label={localizedStrings.name}
        size='small'
        value={filter ? filter.name : ''}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, name: e.target.value }))}
      />
    ),
    description: (
      <TextField
        id='filterDescriptionTextField'
        style={{ marginTop: '10px', width: '100%' }}
        label={localizedStrings.description}
        size='small'
        value={filter ? filter.description : ''}
        variant='outlined'
        onChange={e => setFilter(f => ({ ...f, description: e.target.value }))}
      />
    ),
    isArchived: (
      <Box sx={{ display: 'flex', direction: 'row' }}>
        <CustomCheckbox
          id='filterArchivedCheckbox'
          checked={filter ? filter.showIsArchived : false}
          onChange={e => setFilter(f => ({ ...f, showIsArchived: e.target.checked }))}
        />
        <span style={{ paddingTop: '9px' }}>Show Archived</span>
      </Box>
    ),
    accessLevel: {
      nexus: (
        <CustomSelect
          id='filterAccessLevelSelect'
          style={{ marginTop: '15px', width: '100%' }}
          label={localizedStrings.accessLevel}
          value={filter ? filter.accessLevel : DEFAULT_DOCUMENTS_FILTER.accessLevel}
          onChange={e => setFilter(f => ({ ...f, accessLevel: e.target.value }))}
          options={options}
        />
      ),
      manufacturing: null
    },
    products: (
      <CustomAccordion defaultExpanded={false} disableGutters title={localizedStrings.products} style={{ marginTop: '10px' }}>
        <ListSelector
          selectedOptions={filter ? filter.productTypes : []}
          options={
            productTypes
              ? productTypes.map(pType => ({
                  id: pType.id,
                  value:
                    pType.localization && pType.localization[window.navigator.language]
                      ? pType.localization[window.navigator.language]
                      : pType.name
                }))
              : []
          }
          onChange={products => setFilter(f => ({ ...f, productTypes: products }))}
          style={{ width: '100%', maxHeight: '200px' }}
        />
      </CustomAccordion>
    ),
    documentType: {
      nexus: (
        <CustomAccordion
          defaultExpanded={false}
          disableGutters
          title={localizedStrings.documentType}
          style={{ marginTop: '10px' }}
        >
          <ListSelector
            selectedOptions={filter ? filter.documentTypes : []}
            options={
              documentTypes
                ? documentTypes.map(pType => ({
                    id: pType.id,
                    value:
                      pType.localization && pType.localization[window.navigator.language]
                        ? pType.localization[window.navigator.language]
                        : pType.name
                  }))
                : []
            }
            onChange={products => setFilter(f => ({ ...f, documentTypes: products }))}
            style={{ width: '100%', maxHeight: '200px' }}
          />
        </CustomAccordion>
      ),
      manufacturing: null
    },
    languages: {
      nexus: (
        <CustomAccordion defaultExpanded={false} disableGutters title={localizedStrings.languages} style={{ marginTop: '10px' }}>
          <ListSelector
            selectedOptions={filter ? filter.languageTypes : []}
            options={
              languageTypes
                ? languageTypes.map(pType => ({
                    id: pType.id,
                    value:
                      pType.localization && pType.localization[window.navigator.language]
                        ? pType.localization[window.navigator.language]
                        : pType.name
                  }))
                : []
            }
            onChange={languages => setFilter(f => ({ ...f, languageTypes: languages }))}
            style={{ width: '100%', maxHeight: '200px' }}
          />
        </CustomAccordion>
      ),
      manufacturing: null
    },
    productId: {
      nexus: null,
      manufacturing: (
        <TextField
          id='filterProductIdTextField'
          style={{ width: '100%', marginTop: '10px' }}
          label={localizedStrings.productId}
          size='small'
          value={filter ? filter.productId : ''}
          variant='outlined'
          onChange={e => setFilter(f => ({ ...f, productId: e.target.value }))}
        />
      )
    },
    workInstruction: {
      nexus: null,
      manufacturing: (
        <TextField
          id='filterWorkInstructionTextField'
          style={{ width: '100%', marginTop: '10px' }}
          label={localizedStrings.workInstruction}
          size='small'
          value={filter ? filter.workInstruction : ''}
          variant='outlined'
          onChange={e => setFilter(f => ({ ...f, workInstruction: e.target.value }))}
        />
      )
    }
  }

  if (categoriesLoading) {
    return (
      <CenteredDiv>
        <CircularProgress />
      </CenteredDiv>
    )
  }
  if (categoriesError) {
    return (
      <CenteredDiv>
        <ErrorMessage error={categoriesError} />
      </CenteredDiv>
    )
  }

  if (justDocType === true) {
    return <div>{unitComponents.documentType[unitId]}</div>
  } else {
    return (
      <>
        {unitComponents.name}
        {unitComponents.productId[unitId]}
        {unitComponents.workInstruction[unitId]}
        {unitComponents.accessLevel[unitId]}
        {unitComponents.products}
        {unitComponents.documentType[unitId]}
        {unitComponents.languages[unitId]}
        {unitComponents.description}
        <HasAccess env={env} allowedRoles={['Sales Admin', 'Sales Team Member', 'Packages Admin']}>
          {unitComponents.isArchived}
        </HasAccess>
      </>
    )
  }
}
