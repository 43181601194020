export const localEnv = {
  wavetronix: {
    windowURL: 'mydocuments.wtxdev.com',
    runMode: 'dev',
    clientId: '5a6bca9b-f23d-4c07-842c-dc7cb76059a5',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com',
      documentsURL: 'https://wtx-mydocuments-rest.wtxdev.com',
      // documentsURL: 'http://localhost:5000',
      dataverseConnectorURL: 'https://dataverseconnector.wavetronix.com'
      // dataverseConnectorURL: 'http://localhost:5004'
    },
    basicAuthentication: {}
  },
  islandradar: {
    windowURL: 'mydocuments.islandradardev.com',
    runMode: 'dev',
    clientId: '5a6bca9b-f23d-4c07-842c-dc7cb76059a5',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wtxdev.com/islandradar',
      documentsURL: 'https://wtx-mydocuments-rest.wtxdev.com/islandradar',
      // documentsURL: 'http://localhost:5000/islandradar',
      dataverseConnectorURL: 'https://dataverseconnector.wavetronix.com'
    },
    basicAuthentication: {}
  },
  fathom: {
    windowURL: 'mydocuments.fathomradar.com',
    runMode: 'prod',
    clientId: '63fb3d93-d44c-40c3-9085-8949874b4700',
    urls: {
      gatekeeperURL: 'https://wtx-gatekeeper-rest.wavetronix.com/fathom',
      documentsURL: 'https://wtx-mydocuments-rest.wavetronix.com/fathom',
      // documentsURL: 'http://localhost:5000/fathom',
      dataverseConnectorURL: 'https://dataverseconnector.wavetronix.com'
    },
    basicAuthentication: {}
  }
}
