import { TextField, List, ListItem, ListItemButton } from '@mui/material'
import { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useWtxLocalization, PrimaryButton, WtxColors, SnackbarVariants, CenteredDiv } from '@wavetronix/common-components'
import DataverseConnectorApi from '../../api/DataverseConnectorApi'
import { useCookies } from 'react-cookie'
import { clearAllCache } from '../../utils/cacheUtils'
import { useParams } from 'react-router-dom'

export default function OpportunitiesManagementPage() {
  let localizedStrings = useWtxLocalization()
  const { unitId } = useParams()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [searchOpportunityName, setSearchOpportunityName] = useState('')
  const [cookies, setCookie, removeCookie] = useCookies(['opportunities'])

  const [selectedOpportunity, setSelectedOpportunity] = useState({
    opportunityid: ''
  })
  const [opportunities, setOpportunities] = useState()

  useEffect(() => {
    if (!cookies) {
      setCookie('opportunities', null, { path: '/' })
      setOpportunities(null)
    } else if (cookies && !cookies['opportunities']) {
      setOpportunities(null)
    } else {
      setOpportunities(cookies['opportunities'])
    }
  }, [cookies, setCookie])

  const searchOpportunity = async () => {
    removeCookie('opportunities')
    let key = enqueueSnackbar('Searching opportunities', SnackbarVariants.LOADING)

    await DataverseConnectorApi.getOpportunityByName(encodeURIComponent(searchOpportunityName))
      .then(res => {
        closeSnackbar(key)
        if (res.value.length === 0) {
          enqueueSnackbar('No opportunities found, please try a different search.', SnackbarVariants.SUCCESS)
        }
        setCookie('opportunities', res.value, { path: '/' })
      })
      .catch(error => {
        closeSnackbar(key)
        enqueueSnackbar("Error searching opportunities, make sure search field isn't empty.", SnackbarVariants.ERROR)
        console.log(error)
      })
  }
  return (
    <>
      <div style={{ display: 'flex' }}>
        <div style={{ width: '100%', margin: '10px' }}>
          <div style={{ display: 'flex', marginBottom: '15px' }}>
            <TextField
              id='searchOpportunityTextField'
              style={{ width: '100%', marginTop: '10px' }}
              label={localizedStrings.searchOpportunity}
              size='small'
              value={searchOpportunityName}
              variant='outlined'
              onChange={e => setSearchOpportunityName(e.target.value)}
              onKeyDown={async e => {
                if (e.key === 'Enter') {
                  await searchOpportunity()
                }
              }}
            />
            <PrimaryButton
              id='searchOpportunityButton'
              style={{ marginTop: '10px', marginLeft: '15px', width: '150px' }}
              onClick={searchOpportunity}
              disabled={!searchOpportunityName || searchOpportunityName === ''}
            >
              Search
            </PrimaryButton>
          </div>

          <div>
            {opportunities && opportunities.length > 0 ? (
              <List>
                {opportunities.map((opportunity, id) => {
                  return (
                    <ListItem
                      key={id}
                      sx={{
                        borderBottom: '1px solid black',
                        backgroundColor:
                          opportunity.opportunityid === selectedOpportunity.opportunityid ? WtxColors.IQ_BLUE : 'transparent',
                        color: opportunity.opportunityid === selectedOpportunity.opportunityid ? 'white' : 'black',
                        padding: '5px'
                      }}
                    >
                      <ListItemButton
                        id={`${opportunity.opportunityid}ListItemButton`}
                        onClick={() => {
                          setSelectedOpportunity(opportunity)
                          localStorage.removeItem('opportunityid')
                          localStorage.removeItem('opportunity')

                          clearAllCache()

                          localStorage.setItem('opportunity', JSON.stringify(opportunity))
                          localStorage.setItem('opportunityid', JSON.stringify(opportunity.opportunityid))
                          window.open(`/opportunitypackage/${unitId}`, '_blank')
                        }}
                      >
                        {opportunity.name}
                      </ListItemButton>
                    </ListItem>
                  )
                })}
              </List>
            ) : (
              <CenteredDiv>No opportunities found.</CenteredDiv>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
