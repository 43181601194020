import axios from 'axios'
import { env } from '../index.js'
import { Buffer } from 'buffer'

let instance = null

class DataverseConnectorApi {
  getOpportunityByName = async name => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.dataverseUserName}:${env.basicAuthentication.dataversePassword}`
    ).toString('base64')

    return await axios
      .post(`${env.urls.dataverseConnectorURL}/dataverse/odata/opportunities/name/contains`, name, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Opportunity by Name Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Opportunity may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  getValueByContains = async (table, value, name) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.dataverseUserName}:${env.basicAuthentication.dataversePassword}`
    ).toString('base64')

    return await axios
      .post(`${env.urls.dataverseConnectorURL}/dataverse/odata/${table}/${value}/contains`, name, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Data by Value contains Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Data may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  getValueByEq = async (table, value, id) => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.dataverseUserName}:${env.basicAuthentication.dataversePassword}`
    ).toString('base64')

    return await axios
      .post(`${env.urls.dataverseConnectorURL}/dataverse/comparison/${table}/${value}/eq`, id, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Value by Eq Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Value may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  getOpportunityProductsById = async id => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.dataverseUserName}:${env.basicAuthentication.dataversePassword}`
    ).toString('base64')

    return await axios
      .post(`${env.urls.dataverseConnectorURL}/dataverse/comparison/opportunityproducts/_opportunityid_value/eq`, id, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Opportunity Products by Id Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Opportunity Products may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }

  getProductsThroughQueryList = async ids => {
    let credentials = Buffer.from(
      `${env.basicAuthentication.dataverseUserName}:${env.basicAuthentication.dataversePassword}`
    ).toString('base64')

    return await axios
      .post(`${env.urls.dataverseConnectorURL}/dataverse/existingproducts/productquerylist`, ids, {
        headers: { Authorization: `Basic ${credentials}` },
        timeout: 30000
      })
      .then(result => result.data)
      .catch(error => {
        console.log('Get Products by Id Failed!')
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          if (error.response.status === 400) {
            throw new Error('Products may be incomplete')
          }
          throw new Error('Unexpected Error')
        } else if (error.request) {
          console.log('No Response!')
          console.log(error.request)
          throw new Error('No response from server')
        } else {
          console.log('Other!')
          console.log(error.toString())
          throw new Error('Unexpected Error')
        }
      })
  }
}

const getInstance = () => {
  if (instance == null) {
    instance = new DataverseConnectorApi()
  }
  return instance
}

export default getInstance()
