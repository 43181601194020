const fr = {
  title: 'Mes Documents',
  viewProductDownloads: 'Voir Téléchargements Produits',
  back: 'Retour',
  customerNoDocs: {
    info: "Vous n'avez pas trouvé ce que vous cherchiez?",
    prompt: 'Appelez notre équipe de support technique au (801) 734-7222'
  },
  adminNoDocs: {
    info: "Il n'y a actuellement aucun fichier",
    prompt: 'Cliquez sur le bouton téléverser pour ajouter des fichiers à ce produit.'
  },
  uploadDoc: 'Documents Téléversés',
  availableDocs: 'Rechercher des Documents',
  name: 'Nom',
  description: 'Description',
  productNumber: 'Numéro de produit',
  accessLevel: "Niveau d'Accès",
  associatedProducts: 'Produits Associés',
  documentType: 'Type Document',
  products: 'Produits',
  languages: 'Langues',
  download: 'Téléchargement',
  manageUsers: 'Gérer Utilisateurs',
  firstName: 'Prénom',
  lastName: 'Nom',
  filter: 'Filtrer',
  clear: 'Effacer',
  apply: 'Appliquer',
  cancel: 'Annuler',
  manage: 'Gérer',
  category: 'Catégorie',
  addCategory: 'Ajouter Catégorie',
  productFamily: 'Famille de Produits',
  chooseImage: 'Choisir Image',
  add: 'Ajouter',
  override: 'Ignorer',
  givenName: 'Dénomination',
  snackbar: {
    categoryCreated: 'Création Catégorie Réussie!',
    categoryCreateFailed: 'Création de Catégorie Echouée',
    categoryDeleted: 'Suppression de Catégorie Réussie!',
    categoryDeletedFailed: 'Suppression de Catégorie Echouée',
    categoryUpdated: 'Catégorie Mise à Jour!',
    categoryUpdatedFailed: 'Mise à Jour Catégorie Echouée',
    imageUploaded: 'Image Téléverséé!',
    imageUploadedFailed: 'Téléversement Image Echouée',
    infoUploaded: 'Info Téléversée!',
    infoUploadedFailed: 'Téléversement Document Info Echoué.',
    docUploaded: 'Fichier Téléversé!',
    docUploadedFailed: 'Erreur Inconnue! Nom de Fichier éventuellement dupliqué.',
    updatingUser: 'Actualisation Utilisateur...',
    updatedUser: 'Mise à jour Utilisateur réussie',
    updatedUserFailed: 'Echec Mise à Jour Utilisateur',
    previewNotSupported: 'Prévisualisation de fichier non disponible',
    docDeleted: 'Suppression de Fichier Réussie!',
    docInfoUpdate: 'Actualisation Information...',
    docInfoUpdateFailed: 'Echec Mise à Jour Document',
    docInfoUpdated: 'Mise à Jour Document Réussie',
    docsInfoUpdateFailed: 'Echec Mise à Jour Documents',
    docsInfoUpdated: 'Mise à Jour Documents Réussie',
    publicURLCopied: "URL d'accès public copiée!",
    overrideSuccess: 'Le remplacement a bien été ajouté au document',
    overrideRemoveSuccess: 'Remplacement du document supprimé avec succès',
    overrideFailure: "Échec de l'ajout du remplacement au document",
    overrideRemoveFailure: 'Échec de la suppression du remplacement du document',
    loadingVideo: 'Chargement de la vidéo...'
  },
  updateCategory: 'Mise à Jour Catégorie',
  chooseFile: 'Choisir Fichier',
  save: 'Sauvegarder',
  preview: 'Prévisualisation du document',
  deleteDocument: 'Effacer Document',
  delete: 'Effacer',
  updateDocument: 'Fichier de mise à jour',
  order: 'Ordre',
  email: 'E-mail',
  documents: 'Documentation',
  search: 'Rechercher',
  categories: 'Catégories',
  users: 'Utilisateurs',
  downloadSelected: 'Télécharger Sélectionné',
  deleteSelected: 'Supprimer Sélectionnée',
  deleteAllMessage:
    'Tous les documents répertoriés ci-dessous seront définitivement supprimés du système et les références à ceux-ci seront supprimées de tous les groupes et utilisateurs. Cette action est permanente et ne peut pas être annulée.',
  bundleManager: 'Opportunités',
  searchOpportunity: 'Rechercher des opportunités',
  productType: 'Type de produit',
  packages: 'Forfaits',
  opportunities: 'Opportunités',
  productId: 'Identifiant du produit',
  workInstruction: 'Instructions de travail'
}
export { fr }
