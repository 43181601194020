import ProductTable from '../controls/TitlePageComponents/ProductTable'

export function ProductTableSection({ titlePageIncludes, productIdMap }) {
  if (titlePageIncludes.productTable) {
    return (
      <>
        {titlePageIncludes.titlePage === true ? (
          <hr
            className='breakline'
            style={{
              pageBreakAfter: 'always'
            }}
          />
        ) : null}
        <ProductTable productIdMap={productIdMap} />
      </>
    )
  } else return null
}

export function NotesExclusionsSection({ titlePageIncludes, submittalInfo }) {
  if (titlePageIncludes.notesExclusions && submittalInfo.notes && submittalInfo.notes !== '') {
    return (
      <>
        {titlePageIncludes.productTable ? (
          <hr
            className='breakline'
            style={{
              pageBreakAfter: 'always'
            }}
          />
        ) : null}
        <h3>Notes/Exclusions</h3>

        {submittalInfo.notes && submittalInfo.notes !== '' ? (
          <div style={{ whiteSpace: 'pre-wrap', fontSize: '12px' }}>{submittalInfo.notes}</div>
        ) : null}
      </>
    )
  } else return null
}
