import { IconButton, Snackbar } from '@mui/material'
import {
  CustomDataGrid,
  RegularButton,
  acquireAccessToken,
  parseJwt,
  useWtxLocalization,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { useEffect, useState, useMemo } from 'react'
import { useMsal } from '@azure/msal-react'
import { env } from '../../index.js'
import DocumentsApi from '../../api/DocumentsApi'
import CategoriesApi from '../../api/CategoriesApi'
import DocumentsFilterDrawer, { DEFAULT_DOCUMENTS_FILTER, filterDocuments } from '../drawers/DocumentFilterDrawer'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import PlayVideoModal from '../modals/PlayVideoModal'
import { getVideoType } from '../../utils/stringUtils'
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline'
import { DownloadButtonWtx } from '../controls/DownloadButtonWtx'

export default function DocumentsListDisplayPage({ data, isLoading, options, cookies, setCookie }) {
  const theme = useTheme()
  const { unitId } = useParams()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen
  const { instance, accounts } = useMsal()
  const [downloadToken, setDownloadToken] = useState('')
  const [selectedRows, setSelectedRows] = useState([])
  const [filter, setFilter] = useState(DEFAULT_DOCUMENTS_FILTER)
  const [videoInfo, setVideoInfo] = useState(null)
  const [videoDialogVisible, setVideoDialogVisible] = useState(false)

  let localizedStrings = useWtxLocalization()

  const { data: categories } = useQuery({
    queryKey: ['categories'],
    queryFn: async () => await CategoriesApi.getCategories(instance, accounts)
  })

  const productTypes = useMemo(() => {
    let res = []
    if (categories) {
      res =
        filter.showIsArchived === false
          ? categories.filter(c => c.categoryType === 'Product' && c.isArchived === false)
          : categories.filter(c => c.categoryType === 'Product')
    }
    return res.sort((a, b) => (a.order > b.order ? 1 : -1))
  }, [filter, categories])

  useEffect(() => {
    async function resetDownloadToken() {
      let token = await acquireAccessToken(instance, accounts, env)
      setDownloadToken(token)
    }

    if (!downloadToken || parseJwt(downloadToken).exp < Date.now() / 1000) {
      resetDownloadToken()
    }
  }, [instance, accounts, downloadToken, setCookie])

  const categoryMap = useMemo(() => {
    if (categories) {
      return categories.reduce((map, value) => {
        map[value.id] = value
        return map
      }, {})
    }
  }, [categories])

  const columns = {
    nexus: [
      {
        headerName: localizedStrings.name,
        field: 'fileName',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },

      {
        headerName: localizedStrings.description,
        field: 'description',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.accessLevel,
        field: 'accessLevel',
        flex: 1,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.associatedProducts,
        field: 'productTypes',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true,
        renderCell: u => {
          if (!categoryMap) {
            return <div>Loading products...</div>
          }
          let products = u.row.products
            ? u.row.products.map(pId => {
                if (categoryMap[pId]) {
                  if (
                    categoryMap[pId].localization &&
                    categoryMap[pId].localization[window.navigator.language] &&
                    categoryMap[pId].localization[window.navigator.language] !== ''
                  ) {
                    return categoryMap[pId].localization[window.navigator.language]
                  } else {
                    return categoryMap[pId].name
                  }
                } else {
                  return '...'
                }
              })
            : []

          if (products.includes('...')) {
            return <div>...</div>
          }
          return <div>{products.join(', ')}</div>
        }
      },
      {
        headerName: localizedStrings.documentType,
        field: 'docType',
        flex: 1,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true,
        renderCell: u => {
          let id = u.row.documentType
          let name = u.row.documentType
          if (categoryMap && categoryMap[id]) {
            if (
              categoryMap[id].localization &&
              categoryMap[id].localization[window.navigator.language] &&
              categoryMap[id].localization[window.navigator.language] !== ''
            ) {
              name = categoryMap[id].localization[window.navigator.language]
            } else {
              name = categoryMap[id].name
            }
          }
          return <div>{name}</div>
        }
      },
      {
        headerName: '',
        field: 'download',
        flex: 1,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive,
        renderCell: u => {
          if (getVideoType(u.id).isSuccess) {
            return (
              <IconButton
                id={`${u.id}PlayDownloadButton`}
                className='btn-link'
                color='primary'
                size='large'
                onClick={() => {
                  setVideoInfo(u.row)
                  setVideoDialogVisible(true)
                }}
              >
                <PlayCircleOutlineIcon />
              </IconButton>
            )
          } else {
            var downloadUrl = `${env.urls.documentsURL}/${unitId}/file/throughtoken/${u.id}`
            return (
              <DownloadButtonWtx
                fileName={u.row.fileName}
                id={`${u.id}PlayDownloadButton`}
                url={downloadUrl}
                token={downloadToken}
                variant='icon'
              ></DownloadButtonWtx>
            )
          }
        }
      }
    ],
    manufacturing: [
      {
        headerName: localizedStrings.name,
        field: 'fileName',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },
      {
        headerName: localizedStrings.productId,
        field: 'productId',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },
      {
        headerName: localizedStrings.workInstruction,
        field: 'workInstruction',
        flex: 2,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive
      },
      {
        headerName: localizedStrings.description,
        field: 'description',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true
      },
      {
        headerName: localizedStrings.associatedProducts,
        field: 'productTypes',
        flex: 2,
        hide: isSmallScreen,
        filterable: false,
        disableColumnMenu: true,
        renderCell: u => {
          if (!categoryMap) {
            return <div>Loading products...</div>
          }
          let products = u.row.products
            ? u.row.products.map(pId => {
                if (categoryMap[pId]) {
                  if (
                    categoryMap[pId].localization &&
                    categoryMap[pId].localization[window.navigator.language] &&
                    categoryMap[pId].localization[window.navigator.language] !== ''
                  ) {
                    return categoryMap[pId].localization[window.navigator.language]
                  } else {
                    return categoryMap[pId].name
                  }
                } else {
                  return '...'
                }
              })
            : []

          if (products.includes('...')) {
            return <div>...</div>
          }
          return <div>{products.join(', ')}</div>
        }
      },
      {
        headerName: '',
        field: 'download',
        flex: 1,
        filterable: false,
        disableColumnMenu: largeScreenFeaturesActive,
        renderCell: u => {
          if (getVideoType(u.id).isSuccess) {
            return (
              <IconButton
                id={`${u.id}PlayDownloadButton`}
                className='btn-link'
                color='primary'
                size='large'
                onClick={() => {
                  setVideoInfo(u.row)
                  setVideoDialogVisible(true)
                }}
              >
                <PlayCircleOutlineIcon />
              </IconButton>
            )
          } else {
            var downloadUrl = `${env.urls.documentsURL}/${unitId}/file/throughtoken/${u.id}`
            return (
              <DownloadButtonWtx
                fileName={u.row.fileName}
                id={`${u.id}PlayDownloadButton`}
                url={downloadUrl}
                token={downloadToken}
                variant='icon'
              ></DownloadButtonWtx>
            )
          }
        }
      }
    ]
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={selectedRows.map(r => getVideoType(r.fileName).isSuccess).includes(true)}
        message='Video files (indicated with grey, italized text) will not be downloaded.'
      />
      <PlayVideoModal
        videoInfo={videoInfo}
        open={videoDialogVisible}
        onClose={() => {
          setVideoDialogVisible(false)
          setVideoInfo(null)
        }}
        token={downloadToken}
        unitId={unitId}
      />
      <PersistantFilterDiv
        defaultOpen={largeScreenFeaturesActive}
        page={
          <>
            <div>
              <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                <div style={{ justifyContent: 'left', flex: 1, margin: '20px 20px 0px 24px' }}>
                  <RegularButton
                    id='multiSelectedDownloadButton'
                    disabled={selectedRows.length === 0}
                    onClick={async () => {
                      return await Promise.all(
                        selectedRows
                          .filter(doc => !getVideoType(doc.fileName).isSuccess)
                          .map(doc => DocumentsApi.downloadDocument(instance, accounts, doc, unitId))
                      )
                    }}
                  >
                    {localizedStrings.downloadSelected}
                  </RegularButton>
                </div>
                <div style={{ display: 'inline', float: 'right' }}></div>
              </div>
            </div>
            <div>
              <div style={{ margin: '24px 0px 0px 24px' }}>
                <CustomDataGrid
                  rows={
                    data
                      ? filterDocuments(
                          filter,
                          data,
                          productTypes.map(p => p.id),
                          unitId
                        )
                      : []
                  }
                  loading={isLoading}
                  columns={columns[unitId]}
                  onColumnHeaderClick={e =>
                    e.field === '__check__' ? setSelectedRows(selectedRows.length > 0 ? [] : data) : null
                  }
                  checkboxSelection
                  keepNonExistentRowsSelected
                  onSelectionModelChange={ids => {
                    const selectedIDs = new Set(ids)
                    const selectedRows = data.filter(row => selectedIDs.has(row.id))
                    setSelectedRows(selectedRows)
                  }}
                  getRowClassName={params => {
                    let result =
                      getVideoType(params.row.fileName).isSuccess && selectedRows.map(r => r.id).includes(params.row.id)
                    return `isHidden-${result}`
                  }}
                />
              </div>
            </div>
          </>
        }
        drawer={<DocumentsFilterDrawer filter={filter} setFilter={setFilter} options={options} />}
        resetFilter={() => setFilter(DEFAULT_DOCUMENTS_FILTER)}
      />
    </>
  )
}
