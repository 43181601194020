import { Tab, Tabs } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import '../index.css'
import { env } from '../index.js'

import { useWtxLocalization, WtxColors, checkHasAccess } from '@wavetronix/common-components'
import { useMemo } from 'react'
const classes = {
  tab: {
    textTransform: 'none',
    fontFamily: 'Klavika',
    fontSize: '16px',
    alignItems: 'end',
    padding: '0px 16px 0px 0px',
    color: 'black'
  },
  tabParent: {
    minWidth: '190px'
  }
}

export default function PageNavbar({ value, setPageState, accounts }) {
  const navigate = useNavigate()
  let localizedStrings = useWtxLocalization()
  const { unitId } = useParams()
  const TAB_OPTIONS = useMemo(() => {
    return [
      {
        roles: [
          'Sales Team Member',
          'Sales Admin',
          'Packages Admin',
          'Dealer',
          'Wavetronix Employee',
          env.basicAuthentication.customerRole
        ],
        id: 'documentsTab',
        value: 'documents',
        validUnits: ['nexus']
      },
      {
        roles: [
          'Sales Team Member',
          'Sales Admin',
          'Packages Admin',
          'Dealer',
          'Wavetronix Employee',
          env.basicAuthentication.customerRole
        ],
        id: 'searchTab',
        value: 'search',
        validUnits: ['nexus', 'manufacturing']
      },
      {
        roles: ['Sales Team Member', 'Sales Admin', 'Packages Admin', 'Dealer'],
        id: 'packagesTab',
        value: 'packages',
        validUnits: ['nexus']
      },
      {
        roles: ['Sales Team Member', 'Sales Admin', 'Packages Admin', 'Dealer'],
        id: 'productsTab',
        value: 'products',
        validUnits: ['nexus']
      },
      {
        roles: ['Sales Team Member', 'Sales Admin', 'Packages Admin'],
        id: 'opportunitiesTab',
        value: 'opportunities',
        validUnits: ['nexus']
      }
    ]
  }, [])
  const userTabOptions = useMemo(() => {
    return TAB_OPTIONS.filter(option => {
      return checkHasAccess(option.roles, accounts, env) && option.validUnits.includes(unitId)
    })
  }, [accounts, unitId, TAB_OPTIONS])

  const onChange = option => {
    if (option.validUnits.includes(unitId)) {
      navigate(`/${option.value}/${unitId}`)
    } else {
      console.log('error in change path')
      return
    }

    setPageState(option.value)
  }

  if (userTabOptions.length > 1) {
    return (
      <Tabs
        value={userTabOptions.map(opt => opt.value).includes(value) ? value : userTabOptions[0].value}
        TabIndicatorProps={{
          sx: {
            backgroundColor: WtxColors.IQ_BLUE
          }
        }}
        orientation='vertical'
        sx={classes.tabParent}
      >
        {userTabOptions.map(option => {
          return (
            <Tab
              key={option.id}
              id={option.id}
              value={option.value}
              sx={classes.tab}
              label={localizedStrings[option.value]}
              onClick={() => onChange(option)}
            />
          )
        })}
      </Tabs>
    )
  } else {
    return null
  }
}
